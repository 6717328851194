import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EventsState {
  eventDetails: any | null;
}

const initialState: EventsState = {
  eventDetails: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    receiveEventDetails: (state, action: PayloadAction<any>) => {
      state.eventDetails = action.payload;
    },
  },
});

export const { receiveEventDetails } = eventsSlice.actions;
export default eventsSlice.reducer;
