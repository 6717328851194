import { API } from "api";

export const fetchDBRevision = () => API.get("/admin/current-alembic-revision");

export const fetchTableSizes = () => API.get("/admin/table-sizes");

export const fetchESDocByUserCount = () => API.get("/admin/es-docs-by-user");

export const requestRunMigrations = () =>
  API.get("/admin/run-migrations-as-user");

export const fetchDeploymentsForApp = (app: string) =>
  API.get(`/admin/deployments/${app}/list`);

export const postDeploymentForApp = (
  app: string,
  hash: string,
  lastHash: string
) =>
  API.get(`/admin/deployments/${app}/deploy/${hash}?last_deployed=${lastHash}`);

export const fetchAnythingById = (uniqueKey: string) =>
  API.get(`/admin/global-search/${uniqueKey}`);
