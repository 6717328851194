import { useEffect, useState } from "react";
import LoadingDots from "components/LoadingDots";

import { setToken } from "utils/auth";
import { newPasswordUpdate } from "api/user";

import "./style.css";

const SetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const token = new URLSearchParams(window.location.search).get("reset_token");
  const buttonDisabled =
    !token || !password || isPasswordInvalid || !passwordsMatch;

  useEffect(() => {
    if (!token) {
      setError("Invalid token. Please try again.");
    }
  }, [token]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return;
    }
    if (token) {
      setLoading(true);
      newPasswordUpdate(token, password)
        .then(({ data }) => {
          setToken(data.access_token, true);
          setTimeout(() => (window.location.href = "/"), 200);
        })
        .catch(() => {
          setError("Failed to reset password");
        })
        .finally(() => (global.location = "/"));
    }
  };

  const onCheckPasswordValidity = (e) => {
    setIsPasswordInvalid(!e.target.value || e.target.value.length < 6);
  };

  const onCheckPasswordMatch = (password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    if (confirmPassword) {
      onCheckPasswordMatch(e.target.value, confirmPassword);
    }
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    onCheckPasswordMatch(password, e.target.value);
  };

  return (
    <div>
      <main id="main-content" className="with-header">
        <div className="auth-form--background"></div>
        <div className="auth-form">
          <h1>Classify</h1>
          <form onSubmit={onSubmit}>
            <div className="auth-modal-form-input--container">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                placeholder="********"
                value={password}
                onBlur={onCheckPasswordValidity}
                onChange={onChangePassword}
              />
            </div>

            <div className="auth-modal-form-input--container">
              <label htmlFor="password2">Re-type password</label>
              <input
                id="password2"
                type="password"
                placeholder="re-type your new password"
                value={confirmPassword}
                onBlur={onCheckPasswordValidity}
                onChange={onChangeConfirmPassword}
              />
            </div>
            {error && (
              <div className="auth-modal-form-error">
                Invalid email/password
              </div>
            )}
          </form>
          <button
            disabled={buttonDisabled}
            className="auth-modal-form-submit-btn"
            onClick={onSubmit}
          >
            {loading ? <LoadingDots /> : "Reset password"}
          </button>
        </div>
      </main>
    </div>
  );
};

export default SetNewPassword;
