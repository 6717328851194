import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INote } from "./selectors";

export interface NoteState {
  notesById: { [noteId: string]: INote };
  userNotes: { [userId: string]: string[] };
}

const initialState: NoteState = {
  notesById: {},
  userNotes: {},
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    receiveNotes: (state, action: PayloadAction<INote[]>) => {
      action.payload.forEach((activity) => {
        state.notesById[activity.id] = activity;
      });
    },

    receiveUserNotes: (
      state,
      action: PayloadAction<{ userId: string; notes: INote[] }>
    ) => {
      action.payload.notes.forEach((note) => {
        state.notesById[note.id] = note;
      });

      if (action.payload.notes.length !== 0) {
        if (!state.userNotes[action.payload.userId]) {
          state.userNotes[action.payload.userId] = [];
        }

        state.userNotes[action.payload.userId] = state.userNotes[
          action.payload.userId
        ].concat(action.payload.notes.map((note) => note.id));
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { receiveNotes, receiveUserNotes } = notesSlice.actions;
export default notesSlice.reducer;
