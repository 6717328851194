import { Table } from "evergreen-ui";
import { useHistory } from "react-router-dom";

const ContactListRow = ({ contactList }: any) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(contactList.created_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(contactList.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const deletedDateString = new Date(contactList.deleted_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/contact-list-details/${contactList.id}`)}
    >
      <Table.TextCell>{contactList.id}</Table.TextCell>
      <Table.TextCell>{contactList.name}</Table.TextCell>
      <Table.TextCell>{contactList.type}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>
        {contactList.deleted_at ? deletedDateString : ""}
      </Table.TextCell>
    </Table.Row>
  );
};

const ContactListTable = ({ contactLists }: any) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created AT</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated AT</Table.TextHeaderCell>
        <Table.TextHeaderCell>Deleted AT</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {contactLists?.map((contactList: any) => (
          <ContactListRow contactList={contactList} key={contactList.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ContactListTable;
