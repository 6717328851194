import { useHistory } from "react-router";
import { ArrowsVerticalIcon, Table } from "evergreen-ui";
import { Material } from "store/materials/selectors";

const dateFmt = {
  month: "numeric",
  year: "2-digit",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
} as const;

const MaterialRow = ({ material }: { material: Material }) => {
  const history = useHistory();

  const { id, title, url, last_activity_at, internal_type } = material;

  const updatedDateString = new Date(last_activity_at).toLocaleString(
    navigator.language,
    dateFmt
  );

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/material-details/${id}`)}
    >
      <Table.TextCell>{id}</Table.TextCell>
      <Table.TextCell>{title}</Table.TextCell>
      <Table.TextCell>{url}</Table.TextCell>
      <Table.TextCell>{internal_type}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
    </Table.Row>
  );
};

const MaterialTable = ({
  materials,
  setSortBy,
}: {
  materials: Material[];
  setSortBy: (newValue: string) => void;
}) => {
  const getNewSortState = (e: React.MouseEvent, columnName: string) => {
    setSortBy(columnName);
    e.stopPropagation();
  };

  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "title")}
          style={{ cursor: "pointer" }}
        >
          Title
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Url</Table.TextHeaderCell>
        <Table.TextHeaderCell>Type</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) =>
            getNewSortState(e, "last_activity_at")
          }
          style={{ cursor: "pointer" }}
        >
          Last Activity
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {materials?.map((material) => (
          <MaterialRow material={material} key={material.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default MaterialTable;
