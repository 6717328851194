import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";

import { fetchContactList } from "api/contact_list";
import { getContactListDetail } from "store/contact_lists/selectors";
import { receiveContactListDetails } from "store/contact_lists/slice";
import { Spinner } from "evergreen-ui";

const ContactListDetail = ({ contactListId }: { contactListId: string }) => {
  const contactList = useAppSelector(getContactListDetail);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getContactList = async () => {
      setLoading(true);
      try {
        const { data } = await fetchContactList(contactListId);
        dispatch(receiveContactListDetails(data));
      } catch (e) {
        setError("Failed to fetch contactList");
      } finally {
        setLoading(false);
      }
    };

    if (
      (!contactList || contactList.id !== contactListId) &&
      !loading &&
      !error &&
      contactListId
    ) {
      getContactList();
    }
  }, [loading, contactList, error, dispatch, contactListId]);

  if (!contactList || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(contactList, null, 2)}</pre>
    </div>
  );
};

export default ContactListDetail;
