import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface JobsState {
  jobDetails: any | null;
}

const initialState: JobsState = {
  jobDetails: null,
};

export interface IWorkerJobInDb {
  id: string;
  connector_id: string | null;
  name: string;
  status: string;
  data: Record<string, unknown>;
  meta: Record<string, unknown>;
  root_job_id: string | null;
  created_at: string;
  updated_at: string;
}

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    receiveJobDetails: (state, action: PayloadAction<any>) => {
      state.jobDetails = action.payload;
    },
  },
});

export const { receiveJobDetails } = jobsSlice.actions;
export default jobsSlice.reducer;
