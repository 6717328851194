import { RootState } from "store";

export interface INote {
  id: string;
  title: string;
  data: Record<string, unknown>;
  content: string;
  org_id: string;
  namespace: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

const root = (state: RootState) => state.notes;
const noteById = (state: RootState) => root(state).notesById;
const userNotes = (state: RootState, userId: string) =>
  root(state).userNotes[userId] || [];

export const getNoteById = (state: RootState, id: string) =>
  noteById(state)[id];

export const getUserNotes = (state: RootState, userId: string) =>
  userNotes(state, userId)
    .map((noteId) => getNoteById(state, noteId))
    .filter((d) => !!d);
