import { API } from "api";

export interface IGate {
  gate_name: string;
  gate_value: boolean;
  user_id: string;
}

export const fetchGate = (userId: string, gateName: string) =>
  API.get<IGate>(`/admin/users/${userId}/gate/${gateName}`);
