import { API } from "api";

export const fetchUserLinks = (
  id: string,
  limit: number = 10,
  offset: number = 0,
  sort_column: string = "",
  sort_dir: string = "desc"
) =>
  API.get(
    `/admin/links/?user_id=${id}&limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_dir=${sort_dir}`
  );

export const fetchLink = (id: string) => API.get(`/admin/links/${id}`);
