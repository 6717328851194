import React from "react";
import { connect } from "react-redux";
import { Route, withRouter, Switch } from "react-router-dom";

import { fetchAllConnectors } from "api/connectors";
import { fetchMe } from "api/user";
import LoadingDots from "components/LoadingDots";
import LoginRequiredRoute from "components/LoginRequiredRoute";
import AppUpdateContainer from "components/AppUpdateListener";
import NotificationContainer from "components/Notification";
import { receiveConnectors } from "store/connectors/slice";
import { receiveUser } from "store/user/slice";
import EmailLogin from "views/auth/login";
import NoAccess from "views/auth/noAccess";
import ForgotPassword from "views/auth/forgotPassword";
import SetNewPassword from "views/auth/setNewPassword";
import ActivityDetailView from "views/ActivityDetailView";
import ConnectorDetailView from "views/ConnectorDetailView";
import ContactDetailView from "views/ContactDetailView";
import ContactListDetailView from "views/ContactListDetailView";
import DocumentDetailView from "views/DocumentDetailView";
import MaterialDetailView from "views/MaterialDetailView";
import NotificationDetailView from "views/NotificationDetailView";
import EventDetailView from "views/EventDetailView";
import LinkDetailView from "views/LinkDetailView";
import RuleDetailView from "views/RuleDetailView";
import SystemView from "views/SystemView";
import TaskDetailView from "views/TaskDetailView";

import Home from "./views/home/index";
import UserDetailsContainer from "./views/UserDetails";
import "./App.css";
import NoteDetailView from "views/NoteDetailView";
import WorkerJobDetailView from "views/WorkerJobDetailView";
import TagDetailView from "views/TagDetailView";
import TagRelationDetailView from "views/TagRelationDetailView";

class App extends React.Component<any, { bootstrapping: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      bootstrapping: true,
    };
  }

  componentDidMount() {
    this.bootstrap();
  }

  bootstrap = async () => {
    const { receiveMe, receiveConnectors, location, history } = this.props;

    await Promise.all([
      fetchMe(),
      fetchAllConnectors(),
      new Promise((res) => setTimeout(res, 200)),
    ])
      .then(([meRes, connectorsRes, _ignore]) => {
        receiveMe(meRes.data);
        receiveConnectors(connectorsRes.data);
        const params = new URLSearchParams(location.search);
        const next = params.get("next");
        if (next) {
          history.push(next);
        }
      })
      .catch((e) => {
        const status = e.response ? e.response.status : null;
        if (![401, 403].includes(status)) {
          console.warn("[App.bootstrap] Unexpected error response", e);
        }
      })
      .finally(() => {
        this.setState({ bootstrapping: false });
      });
  };

  render() {
    if (this.state.bootstrapping) {
      return (
        <div className="app-bootstrap--loading">
          Loading
          <LoadingDots />
        </div>
      );
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={EmailLogin} />
          <Route path="/no-access" component={NoAccess} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-new-pw" component={SetNewPassword} />
          <LoginRequiredRoute path="/" exact>
            <Home />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/user-details" exact>
            <UserDetailsContainer />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/user-details/:id">
            <UserDetailsContainer />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/connector-details" exact>
            <ConnectorDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/connector-details/:id">
            <ConnectorDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/material-details" exact>
            <MaterialDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/material-details/:id">
            <MaterialDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/document-details" exact>
            <DocumentDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/document-details/:id">
            <DocumentDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/rule-details/:id">
            <RuleDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/contact-list-details/:id">
            <ContactListDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/link-details/:id">
            <LinkDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/task-details/:id">
            <TaskDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/event-details/:id">
            <EventDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/contact-details/:id">
            <ContactDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/activity-details/:id">
            <ActivityDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/note-details/:id">
            <NoteDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/job-details/:id">
            <WorkerJobDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/tag-details/:id">
            <TagDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/tag-relation-details/:id">
            <TagRelationDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/notification-details/:id">
            <NotificationDetailView />
          </LoginRequiredRoute>
          <LoginRequiredRoute path="/system">
            <SystemView />
          </LoginRequiredRoute>
        </Switch>
        <NotificationContainer />
        <AppUpdateContainer />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const user = state.user;
  return {
    isLoggedIn: !!user,
    user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    receiveMe: (data: any) => dispatch(receiveUser(data)),
    receiveConnectors: (data: any) => dispatch(receiveConnectors(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App as any));
