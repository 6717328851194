import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ILink {
  id: string;
  connector_id: string;
  created_at: string;
  description: string;
  namespace: string;
  normalized_url: string;
  title: string;
  url: string;
  updated_at: string;
  user_id: string;
}
export interface LinksState {
  userLinks: ILink[] | null;
  linkDetails: any | null;
}

const initialState: LinksState = {
  userLinks: null,
  linkDetails: null,
};

export const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    receiveUserLinks: (state, action: PayloadAction<any[]>) => {
      state.userLinks = action.payload;
    },

    receiveLinkDetails: (state, action: PayloadAction<any>) => {
      state.linkDetails = action.payload;
    },
  },
});

export const { receiveUserLinks, receiveLinkDetails } = linksSlice.actions;
export default linksSlice.reducer;
