import { useRef, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { RootState, AppDispatch } from "./store";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePaginationLoading<T>(
  fetchFunction: (
    limit: number,
    offset: number
  ) => Promise<AxiosResponse<T[], any>>,
  reducer: (data: T[]) => PayloadAction<T[]> | PayloadAction<object>,
  getKey: (offset: number) => string,
  onError?: () => void,
  requestMoreForCorrectPaging = false,
  defaultSize = 10
) {
  const [paginatingDataLoading, setPaginatingDataLoading] = useState(false);
  const [paginatingDataError, setPaginatingDataError] = useState(false);
  const [offset, setOffset] = useState(0);
  const dispatch = useAppDispatch();
  const [currentResult, setCurrentResult] = useState<T[]>();
  const dataMap = useRef<Map<string, T[]>>(new Map());
  const previousKey = usePrevious(getKey(offset));
  const newKey = getKey(offset);
  const requestSize = defaultSize + (requestMoreForCorrectPaging ? 1 : 0);

  useEffect(() => {
    if (dataMap.current.has(newKey) && previousKey !== newKey) {
      const preloadedData = dataMap.current.get(newKey) as T[];
      setCurrentResult(preloadedData);
      dispatch(reducer(preloadedData));
      return;
    }

    if (
      !paginatingDataLoading &&
      !paginatingDataError &&
      !dataMap.current.has(newKey) &&
      previousKey !== newKey
    ) {
      setPaginatingDataLoading(true);
      fetchFunction(requestSize, offset)
        .then(({ data }) => {
          dataMap.current.set(newKey, data);
          setCurrentResult(data);
          setPaginatingDataError(false);
          dispatch(reducer(data));
        })
        .catch((e) => {
          if (e !== "No ID") {
            onError?.();
            setPaginatingDataError(true);
          }
        })
        .finally(() => {
          setPaginatingDataLoading(false);
        });
    }
  }, [
    requestSize,
    paginatingDataLoading,
    paginatingDataError,
    offset,
    dispatch,
    fetchFunction,
    defaultSize,
    reducer,
    previousKey,
    getKey,
    newKey,
    onError,
  ]);

  useEffect(() => {
    setOffset(0);
  }, []);

  useEffect(() => {
    setPaginatingDataError(false);
  }, [newKey]);

  const onPreviousPage = (e: React.MouseEvent) => {
    setOffset(Math.max(0, offset - defaultSize));
    e.stopPropagation();
  };
  const onNextPage = (e: React.MouseEvent) => {
    setOffset(offset + defaultSize);
    e.stopPropagation();
  };

  const isFirstPage = offset === 0;
  const isLastPage = !currentResult || currentResult.length !== requestSize;
  const page = Math.floor(offset / defaultSize) + 1;

  return {
    currentResult: currentResult?.slice(0, defaultSize),
    paginatingDataLoading,
    isFirstPage,
    isLastPage,
    onNextPage,
    onPreviousPage,
    page,
  };
}
