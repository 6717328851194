import { useHistory } from "react-router";
import { ArrowsVerticalIcon, Table } from "evergreen-ui";

import { getUserConnectorsById } from "store/connectors/selectors";
import { useAppSelector } from "hooks";

const DocumentRow = ({ document }: any) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(document.created_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(document.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );

  const connector = useAppSelector((state) =>
    getUserConnectorsById(state, document.connector_id)
  );

  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/document-details/${document.id}`)}
    >
      <Table.TextCell>{document.id}</Table.TextCell>
      <Table.TextCell>{document.filename}</Table.TextCell>
      <Table.TextCell>{document.mimetype}</Table.TextCell>
      <Table.TextCell>{document.connector_id}</Table.TextCell>
      <Table.TextCell>{connector?.provider}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
    </Table.Row>
  );
};

const DocumentTable = ({
  documents,
  setSortBy,
  sortByDir,
  setSortByDir,
}: any) => {
  const getNewSortState = (e: React.MouseEvent, columnName: string) => {
    setSortBy(columnName);
    setSortByDir(sortByDir === "asc" ? "desc" : "asc");
    e.stopPropagation();
  };

  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "filename")}
          style={{ cursor: "pointer" }}
        >
          File Name
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Mime Type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Connector ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Provider</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "created_at")}
          style={{ cursor: "pointer" }}
        >
          Created At
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "updated_at")}
          style={{ cursor: "pointer" }}
        >
          Updated At
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {documents?.map((document: any) => (
          <DocumentRow document={document} key={document.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default DocumentTable;
