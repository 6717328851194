import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DiagramTreeIcon, HomeIcon, UserIcon } from "evergreen-ui";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import { expireToken } from "utils/auth";

import "./style.css";

const signOut = () => {
  expireToken();
  window.location.href = "/";
};

const Sidebar = () => {
  return (
    <div className="classify-sidebar--container">
      <div className="classify-sidebar-links--container">
        <NavLink
          to="/"
          activeClassName="active"
          exact
          className="classify-sidebar-link"
        >
          <HomeIcon className="classify-sidebar-link--icon" />
          Home
        </NavLink>
        <NavLink
          to="/user-details"
          activeClassName="active"
          className="classify-sidebar-link"
        >
          <UserIcon className="classify-sidebar-link--icon" />
          User Details
        </NavLink>
        <NavLink
          to="/system"
          activeClassName="active"
          className="classify-sidebar-link"
        >
          <DiagramTreeIcon className="classify-sidebar-link--icon" />
          System Internals
        </NavLink>
      </div>
      <div className="classify-sidebar-links--container"></div>
      <div
        className="classify-sidebar-link classify-sidebar-sign-out-button"
        onClick={signOut}
      >
        <FontAwesomeIcon
          className="classify-sidebar-link--icon"
          icon={faSignOutAlt}
        />
        Signout
      </div>
    </div>
  );
};

export default Sidebar;
