import { API } from "api";

export const fetchNote = (noteId: string) => API.get(`/admin/notes/${noteId}`);

export const fetchUserNotes = (
  userId: string,
  limit: number = 10,
  offset: number = 0
) => API.get(`/admin/notes/?user_id=${userId}&limit=${limit}&offset=${offset}`);

export const hardDeleteNote = (noteId: string) =>
  API.delete(`/admin/notes/${noteId}/hard-delete`);
