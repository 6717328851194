import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { loginViaEmail } from "api/auth";
import LoadingDots from "components/LoadingDots";
import { useAppSelector } from "hooks";
import { getCurrentUser } from "store/user/selector";
import { setToken } from "utils/auth";

import "./style.css";

const goToHome = () => (global.location = "/");

const EmailLogin = () => {
  const history = useHistory();
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [emailLoginPending, setEmailLoginPending] = useState(false);
  const [emailLoginFailed, setEmailLoginFailed] = useState(false);
  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser && currentUser.is_superuser) {
      history.push("/");
    } else if (currentUser && currentUser.is_superuser === false) {
      history.push("/no-access");
    }
  });

  const startEmailLoginPending = () => {
    setEmailLoginFailed(false);
    setEmailLoginPending(true);
  };

  const onEmailLoginSuccess = async ({ data }) => {
    setToken(data.access_token);
    setTimeout(goToHome, 200);
  };

  const onEmailLoginFailed = () => {
    setEmailLoginFailed(true);
    setEmailLoginPending(false);
  };

  const onUpdateEmail = (e) => setEmailInput(e.target.value);

  const onUpdatePassword = (e) => setPasswordInput(e.target.value);

  const onSubmitEmail = async (e) => {
    e.preventDefault();
    startEmailLoginPending();
    loginViaEmail(emailInput, passwordInput)
      .then(onEmailLoginSuccess)
      .catch(onEmailLoginFailed);
  };

  return (
    <div>
      <main id="main-content" className="with-header">
        <div className="auth-form--background"></div>
        <div className="auth-form">
          <h1>Classify</h1>
          <form onSubmit={onSubmitEmail}>
            <div className="auth-modal-form-input--container">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={emailInput}
                placeholder="yours@example.com"
                onChange={onUpdateEmail}
              />
            </div>

            <div className="auth-modal-form-input--container">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="your password"
                value={passwordInput}
                onChange={onUpdatePassword}
              />
            </div>
            {emailLoginFailed && (
              <div className="auth-modal-form-error">
                Invalid email/password
              </div>
            )}
            <button
              type="submit"
              className="auth-modal-form-hidden-submit-btn"
            ></button>
          </form>
          <button
            className="auth-modal-form-submit-btn"
            onClick={onSubmitEmail}
          >
            {emailLoginPending ? <LoadingDots /> : "Go"}
          </button>
        </div>
        <button
          className="auth-modal-form-forgot-btn"
          onClick={() => history.push("/forgot-password")}
        >
          {"Forgot password?"}
        </button>
      </main>
    </div>
  );
};

export default EmailLogin;
