import { RootState } from "store";

export type MaterialType = "LINK" | "DOCUMENT";

export type MaterialContact = {
  id: string;
  namespace: string;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
};

export type MaterialEntity = {
  id: string;
  namespace: string;
  created_at: string;
  updated_at: string;

  // Link props
  url?: string;
  normalized_url?: string;
  title?: string;
  type?: null;

  // Document props
  size_bytes?: number;
  filename?: string;
  mimetype?: string;
  star?: boolean;
};

export type Material = {
  id: string;
  user_id: string;
  title: string;
  url: string;
  dedup_key: string;
  internal_type: MaterialType;
  last_activity_at: string;
  data: Record<string, unknown>;
  activity: Record<string, string | null | string[]>[];
  contacts: MaterialContact[];
  entity: MaterialEntity;
};

const root = (state: RootState) => state.materials;
const userMaterials = (state: RootState, userId: string) =>
  root(state).userMaterials[userId] || [];

export const getUserMaterials = (state: RootState, userId: string) =>
  userMaterials(state, userId);

export const getUserMaterialsCount = (state: RootState, userId: string) =>
  root(state).userMaterialsCount[userId] || 0;
