import { API } from "api";

export const fetchActivity = (activityId: string) =>
  API.get(`/admin/activities/${activityId}`);

export const fetchUserActivities = (
  userId: string,
  limit: number = 10,
  offset: number = 0,
  search: string = "",
) => {
  let path = `/admin/activities/?user_id=${userId}&limit=${limit}&offset=${offset}`;
  if (search){
    path = `${path}&handle_name_id=${search}`
  }
  return API.get(path);
}
  

export const fetchActivityEventsAndRelations = (activityId: string) =>
  API.get(`/admin/activities/${activityId}/events-and-relations`);
