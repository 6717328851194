import { RootState } from "store";

const root = (state: RootState) => state.contacts;
export const getContactDetails = (state: RootState) =>
  root(state).contactDetails;

export const getSearchedUserContacts = (state: RootState) =>
  root(state).userContacts;

export interface IContactHandle {
  source: string;
  handle: string;
  name: string;
}

export interface IContact {
  id: string;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
  handles: IContactHandle[];
}
