import { API } from "api";

export const fetchTask = (taskId: string) =>
  API.get(`/admin/scheduled_tasks/${taskId}`);

export const resetTask = (taskId: string) =>
  API.post(`/admin/scheduled_tasks/${taskId}/reset`);

export const fetchTasksPage = (
  offset: number = 0,
  status: string = "not_started",
  limit: number = 10
) =>
  API.get(
    `/admin/scheduled_tasks/?status=${status}&limit=${limit}&offset=${offset}`
  );
