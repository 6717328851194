import { useState } from "react";

import { resetPasswordViaEmail } from "api/user";
import LoadingDots from "components/LoadingDots";

import "./style.css";

const ForgotPassword = () => {
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onUpdateEmail = (e) => setEmailInput(e.target.value);

  const onSubmitEmail = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    resetPasswordViaEmail(emailInput)
      .then(() => {
        setError("");
        setSuccess(true);
      })
      .catch(() => setError("Please check you email and try again"))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <main id="main-content" className="with-header">
        <div className="auth-form--background"></div>
        <div className="auth-form">
          <h1>Classify</h1>
          <h3>We'll send you reset instructions.</h3>
          <form onSubmit={onSubmitEmail}>
            <div className="auth-modal-form-input--container">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={emailInput}
                placeholder="yours@example.com"
                onChange={onUpdateEmail}
              />
            </div>
            <button
              type="submit"
              className="auth-modal-form-hidden-submit-btn"
            ></button>
          </form>
          {error && <div className="auth-modal-form-error">{error}</div>}
          {success && (
            <div className="auth-modal-form-success">
              Please follow instructions in your inbox
            </div>
          )}
          <button
            className="auth-modal-form-submit-btn"
            onClick={onSubmitEmail}
            disabled={loading}
          >
            {loading ? <LoadingDots /> : "Send email"}
          </button>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
