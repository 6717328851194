import { API } from "api";

export const fetchMaterial = (id: string) => API.get(`/admin/materials/${id}`);

export const fetchUserMaterials = (
  id: string,
  limit: number = 10,
  offset: number = 0,
  activity_source: string = "",
  sort_column: string = ""
) =>
  API.get(
    `/admin/materials/?user_id=${id}&limit=${limit}&offset=${offset}${
      activity_source ? `&activity_source=${activity_source}` : ""
    }&sort_column=${sort_column}`
  );

export const fetchUserMaterialsCount = (userId: string) =>
  API.get(`/admin/materials/count/${userId}`);

export const backfillMaterials = (
  id: string,
  dry_run: boolean,
  entity_type: string,
  before: string,
  max_processed: number
) =>
  API.post(
    `/admin/backfills/materials?user_id=${id}&dry_run=${dry_run}&entity_types=${entity_type}&before=${before}&max_processed=${max_processed}`
  );

export const backfillMaterialsForPins = (id: string, dry_run: boolean) =>
  API.post(
    `/admin/backfills/materials-for-pins?user_id=${id}&dry_run=${dry_run}`
  );

export const backfillMaterialsForNotifications = (
  id: string,
  amount: number,
  dry_run: boolean
) =>
  API.post(
    `/admin/backfills/add-materials-from-notifications?all_users=true&amount=${amount}&dry_run=${dry_run}`,
    { user_ids: [id] }
  );

export const backfillMaterialsFromDocs = (userId: string) =>
  API.get(`/admin/backfills/materials-from-docs?user_id=${userId}`);


export const backfillReprocessMaterials = (userId: string) =>
  API.post(`/admin/backfills/materials/v2?user_ids=${userId}&direction=desc`);