import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Spinner, Table, Button } from "evergreen-ui";

import {
  fetchDBRevision,
  fetchESDocByUserCount,
  fetchTableSizes,
  requestRunMigrations,
} from "api/system";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";
import { useAppDispatch } from "hooks";

const SystemDataView = () => {
  const [loading, setLoading] = useState(false);
  const [runningMigrations, setRunningMigrations] = useState(false);
  const [dbRevision, setDbRevision] = useState<null | {
    current_revision: string;
    status: string;
  }>(null);
  const [tableSizeData, setTableSizeData] = useState<
    null | { table: string; size: number }[]
  >(null);
  const [docCountData, setDocCountData] = useState<any>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [{ data: dbData }, { data: tableData }, { data: esData }] =
          await Promise.all([
            fetchDBRevision(),
            fetchTableSizes(),
            fetchESDocByUserCount(),
          ]);
        setDbRevision(dbData);
        setTableSizeData(tableData);
        setDocCountData(esData);
      } finally {
        setLoading(false);
      }
    };

    if (!tableSizeData && !docCountData && !loading) {
      fetchData();
    }
  });

  const runMigrations = async () => {
    if (runningMigrations) {
      return;
    }

    setRunningMigrations(true);
    try {
      await requestRunMigrations();
    } catch (e) {
      notify({
        message: "Failed to run migrations",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setRunningMigrations(false);
    }
  };

  if (loading) {
    return (
      <div>
        Loading data <Spinner />
      </div>
    );
  }

  return (
    <>
      {dbRevision?.status === "OK" ? (
        <h2>Current DB revision is {dbRevision?.current_revision}</h2>
      ) : (
        <h2>Failed to fetch current DB revision</h2>
      )}
      <div>
        <Button
          isLoading={runningMigrations}
          disabled={runningMigrations}
          onClick={runMigrations}
        >
          Run SQL migrations
        </Button>
      </div>
      <div>
        <h3>SQL Table Sizes</h3>
        <Table width="80%">
          <Table.Head>
            <Table.TextHeaderCell>SQL Table</Table.TextHeaderCell>
            <Table.TextHeaderCell>Row count</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height={300}>
            {(tableSizeData || []).map(({ size, table }) => (
              <Table.Row key={table}>
                <Table.TextCell>{table}</Table.TextCell>
                <Table.TextCell>{size.toLocaleString()}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div>
        <h3>ES Document count by user</h3>
        <div className="system-data-table--help">
          (using the "documents" index)
        </div>
        <Table width="80%">
          <Table.Head>
            <Table.TextHeaderCell>User ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>Number of documents</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height={300}>
            {(docCountData?.aggregations?.user_id?.buckets || []).map(
              ({ doc_count, key }: any) => (
                <Table.Row
                  isSelectable
                  key={key}
                  onClick={() => history.push(`/user-details/${key}`)}
                >
                  <Table.TextCell>{key}</Table.TextCell>
                  <Table.TextCell>{doc_count.toLocaleString()}</Table.TextCell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default SystemDataView;
