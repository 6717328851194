import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IActivity } from "./selectors";

export interface ActivityState {
  activitiesById: { [activityId: string]: IActivity };
  userActivities: { [userId: string]: string[] };
  activityEventsAndRelationsById: { [activityId: string]: unknown };
}

const initialState: ActivityState = {
  activitiesById: {},
  userActivities: {},
  activityEventsAndRelationsById: {},
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    receiveActivities: (state, action: PayloadAction<IActivity[]>) => {
      action.payload.forEach((activity) => {
        state.activitiesById[activity.id] = activity;
      });
    },

    receiveUserActivities: (state, action: PayloadAction<IActivity[]>) => {
      action.payload.forEach((activity) => {
        state.activitiesById[activity.id] = activity;
      });

      if (action.payload.length !== 0) {
        const userId = action.payload[0].user_id;

        if (!state.userActivities[userId]) {
          state.userActivities[userId] = [];
        }

        state.userActivities[userId] = state.userActivities[userId].concat(
          action.payload.map((act) => act.id)
        );
      }
    },

    receiveActivityEventsAndRelations: (
      state,
      action: PayloadAction<{ activityId: string; eventsAndRelations: unknown }>
    ) => {
      state.activityEventsAndRelationsById[action.payload.activityId] =
        action.payload.eventsAndRelations;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  receiveActivities,
  receiveUserActivities,
  receiveActivityEventsAndRelations,
} = activitiesSlice.actions;
export default activitiesSlice.reducer;
