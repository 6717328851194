import { useParams } from "react-router";

import Sidebar from "components/Sidebar";
import EventDetail from "components/EventDetail";

const EventDetailView = () => {
  const params = useParams<{ id: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>Event</h1>
        <EventDetail eventId={params.id} />
      </div>
    </div>
  );
};

export default EventDetailView;
