import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { fetchTagRelation } from "api/tags";
import { useAppDispatch, useAppSelector } from "hooks";
import { getTagRelationDetail } from "store/tags/selectors";
import { receiveTagRelationDetails } from "store/tags/slice";

const getTagRelationId = (relation: any) =>
  `tag:${relation.tag_id}.entity:${relation.entity_type}.${relation.entity_id}`;

const TagRelationDetail = ({ tagRelationId }: { tagRelationId: string }) => {
  const tagRelation = useAppSelector(getTagRelationDetail);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getTag = async () => {
      setLoading(true);
      try {
        const { data } = await fetchTagRelation(tagRelationId);
        dispatch(receiveTagRelationDetails(data));
      } catch (e) {
        setError("Failed to fetch tag relation");
      } finally {
        setLoading(false);
      }
    };

    if (
      (!tagRelation || getTagRelationId(tagRelation) !== tagRelationId) &&
      !loading &&
      !error &&
      tagRelationId
    ) {
      getTag();
    }
  }, [loading, tagRelation, error, dispatch, tagRelationId]);

  if (!tagRelation || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(tagRelation, null, 2)}</pre>
    </div>
  );
};

export default TagRelationDetail;
