import { API } from "api";

export const fetchNotification = (id: string) =>
  API.get(`/admin/notifications/${id}`);

export const fetchUserNotifications = (
  id: string,
  limit = 10,
  offset = 0,
) =>
  API.get(
    `/admin/notifications/?user_id=${id}&limit=${limit}&offset=${offset}`
  );

export const fetchUserNotificationsCount = (userId: string) =>
  API.get(`/admin/notifications/count/${userId}`);
