let ResourcesConstants;

if (process.env.REACT_APP_ENV === "prod") {
  ResourcesConstants = {
    WWW_URL: "https://www.classifyltd.com",
    API_URL: "https://app.api.classify-prod.com",
  };
} else if (process.env.REACT_APP_ENV === "dev") {
  ResourcesConstants = {
    WWW_URL: "https://www.classifyltd.com",
    API_URL: "https://app.api.classify-dev.com",
  };
} else {
  ResourcesConstants = {
    API_URL:
      process.env.REACT_APP_API_URL || "https://app.api.classify-dev.com",
    // API_URL: 'http://localhost:8000',
    WWW_URL: "http://localhost:8000",
  };
}

export const DEBUG = process.env.REACT_APP_ENV !== "prod";
export const API_URL = ResourcesConstants.API_URL;
export const WWW_URL = ResourcesConstants.WWW_URL;
