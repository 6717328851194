import cx from "classnames";

import {
  getAllNotifications,
  INotification,
} from "store/notifications/selectors";
import { hideTransientNotification } from "store/notifications/slice";
import { useAppDispatch, useAppSelector } from "hooks";

import "./style.css";

const Notification = ({
  id,
  message,
  type,
  showDismiss = true,
}: INotification) => {
  const dispatch = useAppDispatch();

  const dismiss = () => dispatch(hideTransientNotification(id));

  return (
    <div className={cx("notification-message--container", type)}>
      <div>{message}</div>
      {showDismiss && (
        <div className="notification-message--dismiss" onClick={dismiss}>
          &times;
        </div>
      )}
    </div>
  );
};

const NotificationContainer = () => {
  const notifications = useAppSelector(getAllNotifications);

  return (
    <div className="notifications--container">
      {notifications.map((notification) => (
        <Notification key={notification.id} {...notification} />
      ))}
    </div>
  );
};

export default NotificationContainer;
