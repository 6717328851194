import { Table } from "evergreen-ui";
import { useHistory } from "react-router-dom";

const RuleRow = ({ rule }: any) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(rule.created_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(rule.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const deletedDateString = new Date(rule.deleted_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/rule-details/${rule.id}`)}
    >
      <Table.TextCell>{rule.id}</Table.TextCell>
      <Table.TextCell>{rule.name}</Table.TextCell>
      <Table.TextCell>{rule.type}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>
        {rule.deleted_at ? deletedDateString : ""}
      </Table.TextCell>
    </Table.Row>
  );
};

const RuleTable = ({ rules }: any) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created AT</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated AT</Table.TextHeaderCell>
        <Table.TextHeaderCell>Deleted AT</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {rules?.map((rule: any) => (
          <RuleRow rule={rule} key={rule.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default RuleTable;
