import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Select from "react-select";
import {
  Table,
  SideSheet,
  Button,
  Pane,
  Heading,
  Text,
  StatusIndicator,
  Card,
  Spinner,
  Dialog,
} from "evergreen-ui";

import {
  fetchConnectorById,
  fetchConnectorDocuments,
  fetchUserConnectors,
  kickoffHistoricalJob,
  postConnectorStatus,
  postScheduleRenew,
} from "api/connectors";
import { getConnectorDetails } from "store/connectors/selectors";
import { getConnectorDocuments } from "store/documents/selectors";
import { useAppDispatch, useAppSelector, usePaginationLoading } from "hooks";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";
import Sidebar from "components/Sidebar";
import DocumentTable from "components/DocumentTable";
import { TablePaginationHeader } from "components/TableUtils/TablePagination";
import {
  receiveConnectorDetails,
  receiveSearchedUserConnectorsById,
} from "store/connectors/slice";
import { receiveConnectorDocuments } from "store/documents/slice";

import "./style.css";

const ConnectorStatusOptions = [
  { value: "ACTIVE", label: "Enabled" },
  { value: "DISABLED", label: "Disabled" },
  { value: "SUSPENDED", label: "Suspended" },
];

const ConnectorDetailView = ({ connectorId }: { connectorId?: string }) => {
  const [isShown, setIsShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [changeStatusError, setChangeStatusError] = useState("");
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [newConnStatus, setNewConnStatus] = useState(ConnectorStatusOptions[0]);
  const [renewLoading, setRenewLoading] = useState(false);
  const [documentsSortedBy, setDocumentsSortedBy] = useState("");
  const [documentsSortedByDir, setDocumentsSortedByDir] = useState("desc");

  const connectorDetails = useAppSelector(getConnectorDetails);
  const connectorDocumentsDetails = useAppSelector(getConnectorDocuments);
  const dispatch = useAppDispatch();

  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };

  const handleKickoffJob = async (connectorId: string) => {
    setLoading(true);
    try {
      await kickoffHistoricalJob(connectorId);
      notify({
        message: "Successfully kicked off historical job",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again.",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setTimeout(() => setLoading(false), 100);
    }
  };

  useEffect(() => {
    if (connectorDetails) {
      fetchUserConnectors(connectorDetails.user_id).then((res) => {
        dispatch(receiveSearchedUserConnectorsById(res.data));
      });
    }
  }, [connectorDetails, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await fetchConnectorById(connectorId!);
        dispatch(receiveConnectorDetails(data));
      } catch (e) {
        setError(true);
        notify({
          message: "Failed to fetch connector.",
          type: NotificationType.ERROR,
        })(dispatch);
      } finally {
        setLoading(false);
      }
    };
    if (
      connectorId &&
      (!connectorDetails || connectorId !== connectorDetails.id) &&
      !loading
    ) {
      fetch();
    }
  }, [connectorId, connectorDetails, dispatch, loading]);

  const {
    paginatingDataLoading,
    isFirstPage,
    isLastPage,
    onNextPage,
    onPreviousPage,
  } = usePaginationLoading(
    (limit, offset) => {
      if (connectorDetails) {
        return fetchConnectorDocuments(
          connectorDetails?.id,
          connectorDetails?.user_id,
          limit,
          offset,
          documentsSortedBy,
          documentsSortedByDir
        );
      }
      return Promise.reject("No ID");
    },
    receiveConnectorDocuments,
    (offset: number) =>
      `${offset}${connectorDetails?.id}${connectorDetails?.user_id}${documentsSortedBy}${documentsSortedByDir}`
  );

  if (loading) {
    return (
      <div>
        Loading
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Could not load the connector</div>;
  }

  if (!connectorDetails) {
    return <div>Could not load the connector</div>;
  }

  const onCloseDialog = () => {
    setShowChangeStatusDialog(false);
  };

  const changeStatus = async () => {
    if (!connectorId) return;

    setLoadingChangeStatus(true);

    try {
      const { data } = await postConnectorStatus(
        connectorId,
        newConnStatus.value
      );
      dispatch(receiveConnectorDetails(data));
      setShowChangeStatusDialog(false);
    } catch (err) {
      console.warn(err);
      setChangeStatusError("failed to change the status");
    }
    setLoadingChangeStatus(false);
  };

  const showRenewButton = ["GMAIL", "GDRIVE", "GCAL"].includes(
    connectorDetails.provider
  );

  const renewWatch = async () => {
    if (!connectorId) return;

    setRenewLoading(true);

    try {
      await postScheduleRenew(connectorId);
      notify({
        message: "Successfully renewed watch 👀",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (err) {
      notify({
        message: "Failed to renew watch",
        type: NotificationType.ERROR,
      })(dispatch);
    }

    setRenewLoading(false);
  };

  return (
    <div>
      <h3>{connectorDetails.provider}</h3>
      <Link to={`/user-details/${connectorDetails.user_id}`}>View owner</Link>
      <div>
        <Table width={1000} marginY={24}>
          <Table.Head>
            <Table.TextHeaderCell>Provider</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>Identifier</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created At</Table.TextHeaderCell>
            <Table.TextHeaderCell>Updated At</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TextCell>{connectorDetails.provider}</Table.TextCell>
              <Table.TextCell>{connectorDetails.status}</Table.TextCell>
              <Table.TextCell>
                {connectorDetails.meta.workspace ||
                  connectorDetails.meta.email ||
                  "unknown"}
              </Table.TextCell>
              <Table.TextCell>
                {new Date(connectorDetails.created_at).toLocaleString(
                  navigator.language,
                  dateFmt
                )}
              </Table.TextCell>
              <Table.TextCell>
                {new Date(connectorDetails.updated_at).toLocaleString(
                  navigator.language,
                  dateFmt
                )}
              </Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button
          size="small"
          padding={15}
          marginTop={-10}
          onClick={() => setIsShown(true)}
        >
          Show More
        </Button>
        <Button
          size="small"
          appearance="primary"
          padding={15}
          marginTop={-10}
          marginLeft={16}
          onClick={() => setShowChangeStatusDialog(true)}
        >
          Change status
        </Button>
        {showRenewButton && (
          <Button
            size="small"
            appearance="primary"
            padding={15}
            marginTop={-10}
            marginLeft={16}
            isLoading={renewLoading}
            disabled={renewLoading}
            onClick={renewWatch}
          >
            Renew webhook watch
          </Button>
        )}
      </div>
      <TablePaginationHeader
        header="Documents"
        loading={paginatingDataLoading}
        isFirstPage={isFirstPage}
        onPreviousPage={onPreviousPage}
        isLastPage={isLastPage}
        onNextPage={onNextPage}
      />
      {connectorDocumentsDetails && (
        <DocumentTable
          documents={connectorDocumentsDetails}
          setSortBy={setDocumentsSortedBy}
          sortByDir={documentsSortedByDir}
          setSortByDir={setDocumentsSortedByDir}
        />
      )}
      <Dialog
        isShown={showChangeStatusDialog}
        title="Change a connector's status"
        onCloseComplete={onCloseDialog}
        confirmLabel="Submit"
        minHeightContent="30vh"
        onConfirm={changeStatus}
        isConfirmLoading={loadingChangeStatus}
        isConfirmDisabled={connectorDetails.status === newConnStatus.value}
      >
        <div>
          <label className="user-gate-dialog--label">New status</label>
          <Select
            onChange={(op) => op && setNewConnStatus(op)}
            value={newConnStatus}
            options={ConnectorStatusOptions}
          />
        </div>
        {changeStatusError}
      </Dialog>
      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
        }}
      >
        <div>
          <Pane backgroundColor={"white"}>
            <Pane
              paddingTop={16}
              paddingBottom={5}
              paddingLeft={16}
              display="flex"
              flexDirection="row"
            >
              <Heading size={600} paddingRight={10}>
                {connectorDetails.provider}
              </Heading>
              <StatusIndicator
                color={
                  connectorDetails.status === "ACTIVE" ? "success" : "disabled"
                }
              >
                {connectorDetails.status}
              </StatusIndicator>
            </Pane>
            <Pane paddingLeft={16} paddingBottom={16} borderBottom="muted">
              <Text>
                {connectorDetails.meta.workspace || connectorDetails.meta.email}
              </Text>
            </Pane>
            <Pane
              display="flex"
              padding={8}
              marginLeft={5}
              borderBottom="muted"
            >
              <Button
                size="small"
                appearance="primary"
                padding={15}
                marginTop={10}
                marginBottom={10}
                isLoading={loading}
                onClick={() => handleKickoffJob(connectorDetails.id)}
              >
                Kickoff Historical Job
              </Button>
            </Pane>
            <Pane padding={16} className="connector-detail-text--container">
              <div>
                <Text>
                  <strong>ID:</strong> {connectorDetails.id}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>User ID:</strong> {connectorDetails.user_id}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>External Account:</strong>{" "}
                  {connectorDetails.external_account_id}
                </Text>
              </div>
              {connectorDetails.meta.workspace && (
                <div className="connector-workspace--wrapper">
                  <div>
                    <Text>
                      <strong>Slack Workspace:</strong>{" "}
                      {connectorDetails.meta.workspace}
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <strong>Workspace ID:</strong>{" "}
                      {connectorDetails.meta.workspace_id}
                    </Text>
                  </div>
                </div>
              )}
              <div>
                <Text>
                  <strong>Created At:</strong>{" "}
                  {new Date(connectorDetails.created_at).toLocaleString(
                    navigator.language,
                    dateFmt
                  )}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Updated At:</strong>{" "}
                  {new Date(connectorDetails.updated_at).toLocaleString(
                    navigator.language,
                    dateFmt
                  )}
                </Text>
              </div>
            </Pane>
            <Pane flex="1" overflowY="scroll" padding={16}>
              <Card
                display="flex"
                flexDirection="column"
                padding={20}
                border="muted"
                elevation={0}
                height={240}
                alignItems="center"
                justifyContent="center"
                background="tint1"
              >
                <pre>
                  {JSON.stringify(
                    connectorDetails,
                    [
                      "status",
                      "provider",
                      "id",
                      "user_id",
                      "external_account_id",
                      "meta",
                      "email",
                      "workspace",
                      "workspace_id",
                      "created_at",
                      "updated_at",
                    ],
                    2
                  )}
                </pre>
              </Card>
            </Pane>
          </Pane>
        </div>
      </SideSheet>
    </div>
  );
};

const ConnectorDetailsContainer = () => {
  const params = useParams<{ id?: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <ConnectorDetailView connectorId={params.id} />
      </div>
    </div>
  );
};

export default ConnectorDetailsContainer;
