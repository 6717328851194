import { useParams } from "react-router";

import Sidebar from "components/Sidebar";
import TagRelationDetail from "components/TagRelationDetail";

const TagRelationDetailView = () => {
  const params = useParams<{ id: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>Tag Relation</h1>
        <TagRelationDetail tagRelationId={params.id} />
      </div>
    </div>
  );
};

export default TagRelationDetailView;
