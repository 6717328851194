import { fetchJobsPage, restartWorkerJob, pauseWorkerJob } from "api/jobs";
import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Spinner,
  Table,
  ChevronLeftIcon,
  ChevronRightIcon,
  SelectField,
  SideSheet,
  Card,
  Pane,
  TextInputField,
} from "evergreen-ui";
import { IWorkerJobInDb } from "store/jobs/slice";
import { workerJobStatuses, WorkerJobStatus } from "constants/workerJobs";

const JobActionButton = ({
  jobId,
  actionToPerform,
  actionMessage,
  successMessage,
}: {
  jobId: string;
  actionToPerform: (jobId: string) => Promise<any>;
  actionMessage: string;
  successMessage: string;
}) => {
  const [acted, setActed] = useState(false);
  const [error, setError] = useState(false);

  const action = async () => {
    setActed(false);
    try {
      await actionToPerform(jobId);
    } catch (e) {
      setError(true);
    } finally {
      setActed(true);
    }
  };

  if (acted) {
    return (
      <Badge
        color="blue"
        paddingTop={8}
        paddingBottom={8}
        paddingLeft={16}
        paddingRight={16}
        height={32}
      >
        {error ? "Error" : successMessage}
      </Badge>
    );
  }

  return (
    <Button size="large" intent="success" onClick={action}>
      {actionMessage}
    </Button>
  );
};

const SystemViewJobs = () => {
  const [pageData, setPageData] = useState<IWorkerJobInDb[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [lastReqOffset, setLastReqOffset] = useState(0);
  const [status, setStatus] = useState<WorkerJobStatus>("in_progress");
  const [lastReqStatus, setLastReqStatus] = useState("in_progess");
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [jobNameFilter, setJobNameFilter] = useState("");
  const [jobConnectorIdFilter, setJobConnectorIdFilter] = useState("");

  const requestNewPage = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchJobsPage(
        offset,
        status,
        jobNameFilter,
        jobConnectorIdFilter
      );
      setPageData(data);
    } catch (e) {
      console.warn(e);
    } finally {
      setLastReqStatus(status);
      setLastReqOffset(offset);
      setLoading(false);
    }
  }, [offset, status, jobNameFilter, jobConnectorIdFilter]);

  useEffect(() => {
    if (
      !loading &&
      (offset !== lastReqOffset ||
        pageData === null ||
        status !== lastReqStatus)
    ) {
      requestNewPage();
    }
  }, [
    offset,
    loading,
    lastReqOffset,
    pageData,
    status,
    lastReqStatus,
    jobNameFilter,
    requestNewPage,
  ]);

  const changeStatus = (e: any) => {
    setStatus(e.target.value);
    setOffset(0);
  };

  // initial loader
  if ((loading && !pageData) || !pageData) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  const onJobNameFilterChange = (e: any) => {
    setJobNameFilter(e.target.value);
  };

  const onJobConnectorIdFilterChange = (e: any) => {
    setJobConnectorIdFilter(e.target.value);
  };

  const maybeSubmit = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setOffset(0);
      requestNewPage();
    }
  };

  return (
    <div>
      <h3>Jobs</h3>
      <div>
        <Button
          iconBefore={ChevronLeftIcon}
          marginBottom={16}
          marginRight={16}
          disabled={offset === 0}
          onClick={() => setOffset(Math.max(0, offset - 10))}
        >
          Previous 10
        </Button>
        <Button
          iconAfter={ChevronRightIcon}
          marginBottom={16}
          disabled={pageData?.length !== 10}
          onClick={() => setOffset(offset + 10)}
        >
          Next 10
        </Button>
        <SelectField
          label="Job status"
          onChange={changeStatus}
          width={240}
          display="inline-block"
          marginLeft={16}
          value={status}
        >
          {workerJobStatuses.map((workerJobStatus) => (
            <option key={workerJobStatus.key} value={workerJobStatus.key}>
              {workerJobStatus.name}
            </option>
          ))}
        </SelectField>
        <TextInputField
          marginBottom={20}
          maxWidth={400}
          label="Worker Job filter by name"
          hint="press enter to find jobs by name"
          placeholder="WorkerJob name"
          onChange={onJobNameFilterChange}
          onKeyDown={maybeSubmit}
        />
        <TextInputField
          marginBottom={20}
          maxWidth={400}
          label="Worker Job filer by connector_id"
          hint="press enter filter jobs by connector_id"
          placeholder="WorkerJob connector_id"
          onChange={onJobConnectorIdFilterChange}
          onKeyDown={maybeSubmit}
        />
      </div>
      <Table width="80%">
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Run date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Action</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {pageData.map((job: IWorkerJobInDb) => (
            <Table.Row key={job.id} isSelectable>
              <Table.TextCell onClick={() => setSelectedJob(job)}>
                {job.name}
              </Table.TextCell>
              <Table.TextCell onClick={() => setSelectedJob(job)}>
                {new Date(job.created_at).toLocaleString("default", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Table.TextCell>
              <Table.TextCell>
                {["paused", "rejected"].includes(status) ? (
                  <JobActionButton
                    jobId={job.id}
                    actionMessage="Restart"
                    successMessage="Restarted"
                    actionToPerform={restartWorkerJob}
                  />
                ) : status === "in_progress" ? (
                  <JobActionButton
                    jobId={job.id}
                    actionMessage="Pause"
                    successMessage="Paused"
                    actionToPerform={pauseWorkerJob}
                  />
                ) : null}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <SideSheet
        isShown={!!selectedJob}
        onCloseComplete={() => setSelectedJob(null)}
      >
        <Pane padding={16}>
          <h2>Task details</h2>
          <Button
            disabled={true}
            isLoading={false}
            onClick={() => {}}
            marginTop={16}
            marginBottom={16}
          >
            Pause
          </Button>
          <Card
            overflow="scroll"
            padding={20}
            border="muted"
            elevation={0}
            height={540}
            alignItems="center"
            justifyContent="center"
            background="tint1"
          >
            <pre>{JSON.stringify(selectedJob, null, 2)}</pre>
          </Card>
        </Pane>
      </SideSheet>
    </div>
  );
};

export default SystemViewJobs;
