import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Spinner,
} from "evergreen-ui";

export const TablePaginationHeader = ({
  loading,
  header,
  isFirstPage,
  onPreviousPage,
  isLastPage,
  onNextPage,
  totalRecords,
  currentPageSize,
  pageSizes,
  setPageSize,
}: {
  loading: boolean;
  header?: string;
  isFirstPage: boolean;
  onPreviousPage: (e: React.MouseEvent) => void;
  isLastPage: boolean;
  onNextPage: (e: React.MouseEvent) => void;
  totalRecords?: number;
  currentPageSize?: number;
  pageSizes?: number[];
  setPageSize?: (pageSize: number) => void;
}) => {
  if (loading) {
    return (
      <div>
        Loading
        <Spinner />
      </div>
    );
  }

  const paginationSize = currentPageSize ?? 10;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        {header && <h3>{header}</h3>}
        <div>
          <Button
            iconBefore={ChevronLeftIcon}
            marginBottom={16}
            marginRight={16}
            disabled={isFirstPage}
            onClick={onPreviousPage}
          >
            Previous {paginationSize}
          </Button>
          <Button
            iconAfter={ChevronRightIcon}
            marginBottom={16}
            disabled={isLastPage}
            onClick={onNextPage}
          >
            Next {paginationSize}
          </Button>
        </div>
      </div>
      {setPageSize !== undefined && pageSizes !== undefined && (
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
        >
          {pageSizes.map((size) => (
            <Button
              appearance={size === paginationSize ? "primary" : undefined}
              key={size}
              onClick={(e: React.MouseEvent) => {
                setPageSize(size);
                e.stopPropagation();
              }}
            >
              {size}
            </Button>
          ))}
        </div>
      )}
      {totalRecords !== undefined && (
        <div style={{ paddingRight: 4, userSelect: "none" }}>
          Total Records: {totalRecords}
        </div>
      )}
    </div>
  );
};
