import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Spinner,
  Table,
  TextInputField,
} from "evergreen-ui";
import { useDispatch } from "react-redux";

import { fetchAnythingById } from "api/system";
import { fetchRecentSignups } from "api/user";
import {
  getSignupsOffset,
  getSignupsOrderedByCreatedAt,
} from "store/user/selector";
import { useAppSelector } from "hooks";
import Sidebar from "components/Sidebar/index";
import { receiveRecentSignups } from "store/user/slice";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";

import "./index.css";

const getTagRelationId = (relation: any) =>
  `tag:${relation.tag_id}.entity:${relation.entity_type}.${relation.entity_id}`;

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const recentSignups = useAppSelector(getSignupsOrderedByCreatedAt);
  const signupsPageOffset = useAppSelector(getSignupsOffset);
  const [offset, setOffset] = useState(signupsPageOffset);
  const [globalSearchInput, setGlobalSearchInput] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setOffset(0);
  }, []);

  useEffect(() => {
    const loadRecentSignups = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchRecentSignups(10, offset);
        dispatch(receiveRecentSignups({ data, pageOffset: offset }));
      } catch (err) {
        console.warn(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && (!recentSignups || offset !== signupsPageOffset)) {
      loadRecentSignups();
    }
  }, [isLoading, dispatch, recentSignups, offset, signupsPageOffset]);

  const onGlobalSearchInputChange = (e: any) => {
    setGlobalSearchInput(e.target.value);
  };

  const findEntity = async () => {
    try {
      const { data } = await fetchAnythingById(globalSearchInput);
      const {
        user,
        connector,
        event,
        document,
        link,
        material,
        contact,
        contact_handle,
        contact_list,
        scheduled_task,
        rule,
        activity,
        note,
        job,
        tag,
        tag_relation,
        notification,
        // lock,
        // org,
        // nonce,
        // calendar_event_entity,
        ...rest
      } = data;
      if (user) {
        history.push(`/user-details/${user.id}`);
      } else if (connector) {
        history.push(`/connector-details/${connector.id}`);
      } else if (material) {
        history.push(`/material-details/${material.id}`);
      } else if (document) {
        history.push(`/document-details/${document.id}`);
      } else if (contact_list) {
        history.push(`/contact-list-details/${contact_list.id}`);
      } else if (rule) {
        history.push(`/rule-details/${rule.id}`);
      } else if (link) {
        history.push(`/link-details/${link.id}`);
      } else if (scheduled_task) {
        history.push(`/task-details/${scheduled_task.id}`);
      } else if (event) {
        history.push(`/event-details/${event.id}`);
      } else if (contact) {
        history.push(`/contact-details/${contact.id}`);
      } else if (contact_handle) {
        history.push(`/contact-details/${contact_handle.contact_id}`);
      } else if (activity) {
        history.push(`/activity-details/${activity.id}`);
      } else if (note) {
        history.push(`/note-details/${note.id}`);
      } else if (job) {
        history.push(`/job-details/${job.id}`);
      } else if (tag) {
        history.push(`/tag-details/${tag.id}`);
      } else if (tag_relation) {
        history.push(`/tag-relation-details/${getTagRelationId(tag_relation)}`);
      } else if (notification) {
        history.push(`/notification-details/${notification.id}`);
      } else {
        notify({
          message:
            "No view for returned type, view the console to see the data",
          type: NotificationType.WARNING,
        })(dispatch);
        console.info(rest);
      }
    } catch (err) {
      console.warn(err);
      notify({
        message: "Could not find anything with that ID",
        type: NotificationType.WARNING,
      })(dispatch);
    }
  };

  const maybeSubmit = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      findEntity();
    }
  };

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="recent-signup--container">
        <h1>Recent Signups</h1>
        <TextInputField
          marginLeft={40}
          marginTop={24}
          marginBottom={32}
          maxWidth={400}
          label="Global search by ID"
          hint="press enter to find anything by its ID"
          placeholder="UUID or a user email"
          onChange={onGlobalSearchInputChange}
          onKeyDown={maybeSubmit}
        />
        {!recentSignups && (
          <div className="recent-signup-table">
            Loading <Spinner />
          </div>
        )}
        {!!recentSignups && (
          <div className="recent-signup-table">
            <div>
              <Button
                iconBefore={ChevronLeftIcon}
                marginBottom={16}
                marginRight={16}
                disabled={offset === 0}
                onClick={() => setOffset(Math.max(0, offset - 10))}
              >
                Previous 10
              </Button>
              <Button
                iconAfter={ChevronRightIcon}
                marginBottom={16}
                disabled={recentSignups.length !== 10}
                onClick={() => setOffset(offset + 10)}
              >
                Next 10
              </Button>
            </div>
            <div className="recent-signups--page">
              Page {signupsPageOffset / 10 + 1}
            </div>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                <Table.TextHeaderCell>Signed up at</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {recentSignups.map((signup) => (
                  <Table.Row
                    isSelectable
                    key={signup.id}
                    onClick={() => history.push(`/user-details/${signup.id}`)}
                  >
                    <Table.TextCell>{signup.full_name}</Table.TextCell>
                    <Table.TextCell>{signup.email}</Table.TextCell>
                    <Table.TextCell>{signup.status}</Table.TextCell>
                    <Table.TextCell>
                      {new Date(signup.created_at).toLocaleString("default", {
                        month: "short",
                        day: "numeric",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
