import { API } from "api";
import { IContact } from "store/contacts/selectors";

export const fetchUserContacts = (
  id: string,
  limit: number = 10,
  offset: number = 0
) =>
  API.get<IContact[]>(
    `/admin/contacts/?user_id=${id}&limit=${limit}&offset=${offset}`
  );

export const fetchContact = (id: string) =>
  API.get<IContact>(`/admin/contacts/${id}`);
