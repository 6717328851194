import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ContactListsState {
  userContactLists: any[] | null;
  contactListDetails: any | null;
}

const initialState: ContactListsState = {
  userContactLists: null,
  contactListDetails: null,
};

export const contactListsSlice = createSlice({
  name: "contactLists",
  initialState,
  reducers: {
    receiveUserContactLists: (state, action: PayloadAction<any[]>) => {
      state.userContactLists = action.payload;
    },

    receiveContactListDetails: (state, action: PayloadAction<any>) => {
      state.contactListDetails = action.payload;
    },
  },
});

export const { receiveUserContactLists, receiveContactListDetails } =
  contactListsSlice.actions;
export default contactListsSlice.reducer;
