import { useHistory } from "react-router";
import { Table } from "evergreen-ui";
import { UserDocsAmountPerConn } from "../../store/documents/slice";

import "./style.css";

const UserConnectorRows = ({
  connector,
  docsAmountPerConnector,
}: {
  connector: any;
  docsAmountPerConnector: UserDocsAmountPerConn;
}) => {
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/connector-details/${connector.id}`)}
      className="connector-row"
    >
      <Table.TextCell>{connector.provider}</Table.TextCell>
      <Table.TextCell>{connector.meta.workspace}</Table.TextCell>
      <Table.TextCell>{connector.meta.email}</Table.TextCell>
      <Table.TextCell>{connector.status}</Table.TextCell>
      <Table.TextCell>
        {docsAmountPerConnector[connector.id] || 0}
      </Table.TextCell>
    </Table.Row>
  );
};

const ConnectorTable = ({
  connectors,
  docsAmountPerConnector,
}: {
  connectors: any;
  docsAmountPerConnector: UserDocsAmountPerConn;
}) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>Provider</Table.TextHeaderCell>
        <Table.TextHeaderCell>Workspace</Table.TextHeaderCell>
        <Table.TextHeaderCell>Email</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Docs</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {connectors?.map((connector: any) => (
          <UserConnectorRows
            connector={connector}
            docsAmountPerConnector={docsAmountPerConnector}
            key={connector.id}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ConnectorTable;
