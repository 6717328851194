import { Dialog, Switch } from "evergreen-ui";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";

import { GateOptions } from "constants/gates";
import { patchGate } from "api/user";
import { useAppDispatch } from "hooks";
import { receiveUserDetails } from "store/user/slice";
import { IUser } from "store/user/selector";

import "./style.css";

const GateValues = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const UserGateDialog = ({
  visible = false,
  current = [],
  onClose,
  userId,
}: {
  visible?: boolean;
  current: IUser["properties"];
  onClose: () => void;
  userId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [gateValue, setGateValue] = useState(GateValues.ENABLED);
  const [selectedGate, setGate] = useState<null | {
    label: string;
    value: string;
  }>(null);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const existingValue = current?.filter(
    (x) => x.namespace === "GATES" && x.name === selectedGate?.value
  )[0];

  const updateGate = async () => {
    if (selectedGate === null) {
      setError("You must select a gate");
      return;
    }

    setLoading(true);
    try {
      const { data } = await patchGate(
        userId,
        selectedGate.value,
        gateValue === GateValues.ENABLED
      );
      dispatch(receiveUserDetails(data));
    } catch (e) {
      console.warn(e);
      setError("Failed to update the user's gates");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      isShown={visible}
      title="Change a user's gates"
      onCloseComplete={onClose}
      confirmLabel="Submit"
      minHeightContent="50vh"
      onConfirm={updateGate}
      isConfirmLoading={loading}
      isConfirmDisabled={!selectedGate}
    >
      <div>
        <label className="user-gate-dialog--label">Gate</label>
        <CreatableSelect
          onChange={setGate}
          value={selectedGate}
          options={GateOptions}
          formatCreateLabel={(inputValue: string) =>
            `Use "${inputValue}" as the gate name`
          }
        />
      </div>
      <div>
        <label className="user-gate-dialog--label">Current state</label>
        <Switch
          className="user-gate-dialog--switch"
          height={24}
          checked={existingValue?.value}
        />
        <div className="user-gate-dialog-switch--help">
          ({existingValue?.value ? "enabled" : "disabled"})
        </div>
      </div>
      <div>
        <label className="user-gate-dialog--label">Change to</label>
        <Switch
          className="user-gate-dialog--switch"
          height={24}
          checked={gateValue === GateValues.ENABLED}
          onChange={() =>
            setGateValue(
              gateValue === GateValues.ENABLED
                ? GateValues.DISABLED
                : GateValues.ENABLED
            )
          }
        />
        <div className="user-gate-dialog-switch--help">
          ({gateValue === GateValues.ENABLED ? "enabled" : "disabled"})
        </div>
      </div>
      {error}
    </Dialog>
  );
};

export default UserGateDialog;
