import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Spinner, Button } from "evergreen-ui";

import Sidebar from "components/Sidebar";
import LinkTable from "components/LinkTable";

import { reingestFileEvent } from "api/file_events";
import { fetchAnythingById } from "api/system";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";

const dateFmt = {
  month: "numeric",
  year: "2-digit",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
} as const;

const NotificationDetailView = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reingesting, setReingesting] = useState(false);

  const [notificationDetails, setNotificationDetails] = useState<
    | {
        id: string;
        user_id: string;
        sent_at: string;
        timestamp: null;
        actor: string;
        message: string;
        action_type: string;
        event_id: string;
        entity_id: string;
        entity_type: "LINK";
        meta: {
          notification_platform: string;
        };
        link: {
          connector_id: string;
          url: string;
          user_id: string;
          namespace: string;
          title: string;
          description: null;
          data: {
            title: string;
          };
        };
      }
    | undefined
  >();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAnythingById(id!);
        if (data.notification) {
          setNotificationDetails(data.notification);
        }
      } catch (e) {
        setError(true);
        notify({
          message: "Failed to fetch notification.",
          type: NotificationType.ERROR,
        })(dispatch);
      } finally {
        setLoading(false);
      }
    };
    if (
      id &&
      (!notificationDetails || id !== notificationDetails.id) &&
      !loading &&
      !error
    ) {
      fetch();
    }
  }, [id, notificationDetails, dispatch, loading, error]);

  const reingest = async () => {
    if (!notificationDetails) {
      return;
    }

    setReingesting(true);
    try {
      await reingestFileEvent(notificationDetails.event_id);

      notify({
        message: "Reingesting file event",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (err) {
      notify({
        message: "Failed to reingest file event",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setReingesting(false);
    }
  };

  if (loading) {
    return (
      <div>
        Loading
        <Spinner />
      </div>
    );
  }

  if (error || !notificationDetails) {
    return <div>Could not load the notification</div>;
  }

  const {
    user_id,
    link,
    actor,
    sent_at,
    message,
    action_type,
    event_id,
    entity_type,
    meta,
  } = notificationDetails;
  const { notification_platform } = meta ?? {};
  const { connector_id, url, title, data } = link ?? {};
  const { title: linkTitle } = data ?? {};

  const notificationDate = new Date(sent_at).toLocaleString(
    navigator.language,
    dateFmt
  );

  return (
    <div>
      <h3>{title}</h3>
      <Link to={`/user-details/${user_id}`}>View owner</Link>
      <div>
        <Table width={1000} marginY={24}>
          <Table.Head>
            <Table.TextHeaderCell>Actor</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Activity At</Table.TextHeaderCell>
            <Table.TextHeaderCell>URL</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Action type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Notification platform</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TextCell>{actor}</Table.TextCell>
              <Table.TextCell>{notificationDate}</Table.TextCell>
              <Table.TextCell>{url}</Table.TextCell>
              <Table.TextCell>{title}</Table.TextCell>
              <Table.TextCell>{entity_type}</Table.TextCell>
              <Table.TextCell>{action_type}</Table.TextCell>
              <Table.TextCell>{notification_platform}</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <Button
        appearance="primary"
        marginTop={-10}
        isLoading={reingesting}
        disabled={!notificationDetails || reingesting}
        onClick={reingest}
      >
        Reingest From File Event
      </Button>

      <h3>Message</h3>
      {message}

      <h3>Related link</h3>
      {!!link && <LinkTable links={[link]} />}
      {linkTitle && (
        <>
          <h3>Link title</h3>
          {linkTitle}
        </>
      )}

      <h3>Related event</h3>
      <Link to={`/event-details/${event_id}`}>View event</Link>

      <h3>Related connector</h3>
      <Link to={`/connector-details/${connector_id}`}>View connector</Link>
    </div>
  );
};

const NotificationDetailsContainer = () => {
  const params = useParams<{ id?: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        {params.id && <NotificationDetailView id={params.id} />}
      </div>
    </div>
  );
};

export default NotificationDetailsContainer;
