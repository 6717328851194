import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { fetchContact } from "api/contacts";
import { useAppDispatch, useAppSelector } from "hooks";
import { getContactDetails } from "store/contacts/selectors";
import { receiveContactDetails } from "store/contacts/slice";

const ContactDetail = ({ contactId }: { contactId: string }) => {
  const contact = useAppSelector(getContactDetails);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getContact = async () => {
      setLoading(true);
      try {
        const { data } = await fetchContact(contactId);
        dispatch(receiveContactDetails(data));
      } catch (e) {
        setError("Failed to fetch contact");
      } finally {
        setLoading(false);
      }
    };

    if (
      (!contact || contact.id !== contactId) &&
      !loading &&
      !error &&
      contactId
    ) {
      getContact();
    }
  }, [loading, contact, error, dispatch, contactId]);

  if (!contact || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(contact, null, 2)}</pre>
    </div>
  );
};

export default ContactDetail;
