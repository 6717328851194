import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  SideSheet,
  Button,
  Pane,
  Heading,
  Text,
  Card,
  Spinner,
} from "evergreen-ui";

import { api } from "api";
import Sidebar from "components/Sidebar";
import { getDocumentDetails } from "store/documents/selectors";
import { useAppSelector } from "hooks";
import {
  fetchDocumentDetails,
  recreateDocumentActivity,
  reindexDocument,
} from "api/documents";
import { reingestFileEvent } from "api/file_events";
import { receiveDocumentDetails } from "store/documents/slice";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";

import "./style.css";

const DocumentDetailFileEvents = ({ event }: any) => {
  const prefetchEvent = api.usePrefetch("fetchEvent");

  return (
    <div>
      <div>
        <Text>
          <strong>ID:</strong>{" "}
          <Link
            to={`/event-details/${event.id}`}
            onMouseEnter={() => prefetchEvent(event.id)}
          >
            {event.id}
          </Link>
        </Text>
      </div>
      <div>
        <Text>
          <strong>User ID:</strong> {event.user_id}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Connector ID:</strong> {event.connector_id}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Dedup Key:</strong> {event.dedup_key}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Processed At:</strong> {event.meta.processed_at}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Processed By:</strong> {event.meta.processed_by}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Timestamp:</strong> {event.timestamp}
        </Text>
      </div>
    </div>
  );
};

const DocumentDetailsSender = ({ sender }: any) => {
  return (
    <div>
      <div>
        <Text>
          <strong>Contact Handle:</strong> {sender.contact_handle_id}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Subject:</strong> {sender.subject}
        </Text>
      </div>
      <div>
        <Text>
          <strong>Shared At:</strong> {sender.shared_at}
        </Text>
      </div>
    </div>
  );
};

const DocumentFileEventRelations = ({ relation }: any) => {
  return (
    <div>
      <div>
        <Text>
          <strong>Document ID:</strong> {relation.document_id}
        </Text>
      </div>
      <div>
        <Text>
          <strong>File Event ID:</strong> {relation.file_event_id}
        </Text>
      </div>
    </div>
  );
};

const DocumentDetailView = ({ documentId }: { documentId?: string }) => {
  const [isShown, setIsShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reindexing, setReindexing] = useState(false);
  const [reingesting, setReingesting] = useState(false);
  const [recreatingActivity, setRecreatingActivity] = useState(false);

  const documentDetails = useAppSelector(getDocumentDetails);
  const dispatch = useDispatch();

  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createDate = new Date(
    documentDetails ? documentDetails.created_at : ""
  ).toLocaleString(navigator.language, dateFmt);
  const updateDate = new Date(
    documentDetails ? documentDetails.updated_at : ""
  ).toLocaleString(navigator.language, dateFmt);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await fetchDocumentDetails(documentId!);
        dispatch(receiveDocumentDetails(data));
      } catch (e) {
        setError(true);
        notify({
          message: "Failed to fetch document.",
          type: NotificationType.ERROR,
        })(dispatch);
      } finally {
        setLoading(false);
      }
    };
    if (
      documentId &&
      (!documentDetails || documentId !== documentDetails.id) &&
      !loading
    ) {
      fetch();
    }
  }, [documentId, documentDetails, dispatch, loading]);

  const reingest = async () => {
    if (!documentDetails || documentDetails.file_events.length === 0) {
      return;
    }

    setReingesting(true);
    try {
      await reingestFileEvent(documentDetails.file_events[0].id);
      notify({
        message: "Reingesting file event",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (err) {
      notify({
        message: "Failed to reingest file event",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setReingesting(false);
    }
  };

  const reindex = async () => {
    if (!documentDetails) {
      return;
    }
    setReindexing(true);
    try {
      await reindexDocument(documentDetails.id);
      notify({
        message: "Reindexing document",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (err) {
      notify({
        message: "Failed to reindex document",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setReindexing(false);
    }
  };

  const recreateActivity = async () => {
    if (!documentId) return;

    setRecreatingActivity(true);

    try {
      const res = await recreateDocumentActivity(documentId);
      const reqId = res.headers["x-request-id"];
      notify({
        message: `Successfully reingest file event. Request ID: ${reqId}`,
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (err) {
      notify({
        message: `Failed to recreate activity from document.`,
        type: NotificationType.WARNING,
      })(dispatch);
    } finally {
      setRecreatingActivity(false);
    }
  };

  if (loading) {
    return (
      <div>
        Loading
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Could not load the document</div>;
  }

  if (!documentDetails) {
    return <div>Could not load the document</div>;
  }

  return (
    <div>
      <h3>{documentDetails.filename}</h3>
      <Link to={`/user-details/${documentDetails.user_id}`}>View owner</Link>
      <div>
        <Table width={1000} marginY={24}>
          <Table.Head>
            <Table.TextHeaderCell>File Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Mimetype</Table.TextHeaderCell>
            <Table.TextHeaderCell>Star</Table.TextHeaderCell>
            <Table.TextHeaderCell>Size Bytes</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created At</Table.TextHeaderCell>
            <Table.TextHeaderCell>Updated At</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TextCell>{documentDetails.filename}</Table.TextCell>
              <Table.TextCell>{documentDetails.mimetype}</Table.TextCell>
              <Table.TextCell>{documentDetails.star.toString()}</Table.TextCell>
              <Table.TextCell>{documentDetails.size_bytes}</Table.TextCell>
              <Table.TextCell>{createDate}</Table.TextCell>
              <Table.TextCell>{updateDate}</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <Button
        size="small"
        padding={15}
        marginTop={-10}
        onClick={() => setIsShown(true)}
      >
        Show More
      </Button>
      <Button
        appearance="primary"
        marginTop={-10}
        isLoading={reindexing}
        disabled={!documentDetails || reindexing}
        onClick={reindex}
        marginLeft={16}
      >
        Reindex
      </Button>
      <Button
        appearance="primary"
        marginTop={-10}
        isLoading={reingesting}
        disabled={!documentDetails || reingesting}
        onClick={reingest}
        marginLeft={16}
      >
        Reingest Latest Event
      </Button>
      <Button
        appearance="primary"
        marginTop={-10}
        isLoading={recreatingActivity}
        disabled={!documentDetails || recreatingActivity}
        onClick={recreateActivity}
        marginLeft={16}
      >
        Recreate activity from events
      </Button>
      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
        }}
      >
        {documentDetails && (
          <div>
            <Pane backgroundColor={"white"}>
              <Pane
                paddingTop={16}
                paddingBottom={5}
                paddingLeft={16}
                display="flex"
                flexDirection="row"
              >
                <Heading size={600} paddingRight={10}>
                  {documentDetails.filename}
                </Heading>
              </Pane>
            </Pane>
            <Pane paddingLeft={16} paddingBottom={16} borderBottom="muted">
              <Text>
                {documentDetails.mimetype ? documentDetails.mimetype : "N/A"}
              </Text>
            </Pane>
            <Pane
              padding={16}
              borderBottom="muted"
              className="document-detail-text--container"
            >
              <div>
                <Text>
                  <strong>ID:</strong> {documentDetails.id}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>User ID:</strong> {documentDetails.user_id}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Connector ID:</strong> {documentDetails.connector_id}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Star:</strong> {documentDetails.star.toString()}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Size Bytes:</strong> {documentDetails.size_bytes}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Created At</strong> {createDate}
                </Text>
              </div>
              <div>
                <Text>
                  <strong>Updated At:</strong> {updateDate}
                </Text>
              </div>
            </Pane>
            {documentDetails.file_events && (
              <Pane
                padding={16}
                borderBottom="muted"
                className="document-detail-text--container"
              >
                <h3>File Events</h3>
                {documentDetails.file_events.map((event: any) => (
                  <DocumentDetailFileEvents event={event} key={event.id} />
                ))}
              </Pane>
            )}
            {documentDetails.sender && (
              <Pane
                padding={16}
                borderBottom="muted"
                className="document-detail-text--container"
              >
                <h3>Sender</h3>
                {documentDetails.sender.map((sender: any, index) => (
                  <DocumentDetailsSender sender={sender} key={index} />
                ))}
              </Pane>
            )}
            {documentDetails.file_event_relations && (
              <Pane
                padding={16}
                borderBottom="muted"
                className="document-detail-text--container"
              >
                <h3>File Event Relations</h3>
                {documentDetails.file_event_relations.map(
                  (relation: any, index) => (
                    <DocumentFileEventRelations
                      relation={relation}
                      key={index}
                    />
                  )
                )}
              </Pane>
            )}
            <Pane flex="1" overflowY="scroll" padding={16}>
              <h3>Overview</h3>
              <Card
                overflow="scroll"
                padding={20}
                border="muted"
                elevation={0}
                height={540}
                alignItems="center"
                justifyContent="center"
                background="tint1"
              >
                <pre>{JSON.stringify(documentDetails, null, 2)}</pre>
              </Card>
            </Pane>
          </div>
        )}
      </SideSheet>
    </div>
  );
};

const DocumentDetailsContainer = () => {
  const params = useParams<{ id?: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <DocumentDetailView documentId={params.id} />
      </div>
    </div>
  );
};

export default DocumentDetailsContainer;
