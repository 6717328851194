import { Button, Spinner } from "evergreen-ui";
import { useEffect } from "react";

import { api } from "api";
import { useAppDispatch } from "hooks";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";

const EventDetail = ({ eventId }: { eventId: string }) => {
  const { isLoading, isError, data } = api.useFetchEventQuery(eventId);
  const dispatch = useAppDispatch();
  const [
    reingestEvent,
    { isLoading: loadingReingest, data: reingestRes, isError: reingestError },
  ] = api.useReingestFileEventMutation();

  useEffect(() => {
    if (reingestError) {
      notify({
        message: "Failed to reingest file event.",
        type: NotificationType.WARNING,
      })(dispatch);
    }
  }, [reingestError, dispatch]);

  useEffect(() => {
    if (reingestRes && reingestRes.requestId) {
      notify({
        message: `Successfully reingest file event. Request ID ${reingestRes.requestId}`,
        type: NotificationType.SUCCESS,
      })(dispatch);
    }
  }, [reingestRes, dispatch]);

  if (!data || isLoading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div>Failed to fetch</div>;
  }

  return (
    <div>
      <div>
        <Button
          onClick={() => reingestEvent(eventId)}
          isLoading={loadingReingest}
          disabled={loadingReingest}
        >
          Reingest
        </Button>
      </div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default EventDetail;
