import { Redirect, Route, useLocation } from "react-router-dom";
import { getCurrentUser } from "store/user/selector";
import { useAppSelector } from "hooks";

const LoginRequiredRoute = ({ children, ...rest }) => {
  const user = useAppSelector(getCurrentUser);
  const location = useLocation();
  const isLoggedIn = user && user.is_superuser;
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect to={`/login?next=${location.pathname}`} />
        )
      }
    />
  );
};

export default LoginRequiredRoute;
