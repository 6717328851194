import { API } from "api";
import { IWorkerJobInDb } from "store/jobs/slice";

export const fetchWorkerJob = (jobId: string) =>
  API.get(`/admin/jobs/${jobId}`);

export const restartWorkerJob = (jobId: string) =>
  API.post(`/admin/jobs/${jobId}/restart`);

export const pauseWorkerJob = (jobId: string) =>
  API.post(`/admin/jobs/${jobId}/pause`);

export const fetchJobsPage = (
  offset: number = 0,
  status: string = "in_progress",
  name: string = "",
  connector_id: string = "",
  limit: number = 10
) => {
  const params = new URLSearchParams();
  params.append("status", status);
  params.append("limit", String(limit));

  if (name) {
    params.append("name", name);
  }

  if (connector_id) {
    params.append("connector_ids", connector_id);
  }

  return API.get<IWorkerJobInDb[]>(`/admin/jobs/?${params.toString()}`);
};
