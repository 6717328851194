import { Table } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { IActivity } from "store/activities/selectors";

const ActivityRow = ({ activity }: { activity: IActivity }) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(activity.timestamp).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(activity.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const deletedDateString = new Date(activity.deleted_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/activity-details/${activity.id}`)}
    >
      <Table.TextCell>{activity.id}</Table.TextCell>
      <Table.TextCell>{activity.type}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>
        {activity.deleted_at ? deletedDateString : ""}
      </Table.TextCell>
    </Table.Row>
  );
};

const ActivityTable = ({ activities }: { activities?: IActivity[] }) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Deleted at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {activities?.map((activity: any) => (
          <ActivityRow activity={activity} key={activity.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ActivityTable;
