import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { expireToken } from "utils/auth";

import "./style.css";
import "../../components/Sidebar/style.css";

const signOut = () => {
  expireToken();
  window.location.href = "/";
};

const NoAccess = () => {
  return (
    <div>
      <main id="main-content" className="with-header">
        <div className="auth-form--background"></div>
        <div className="auth-form">
          <h1>Classify</h1>
          <p style={{ marginLeft: 32 }}>
            Sorry, you do not seem to have access to this resource.
          </p>
          <div
            className="classify-sidebar-link classify-sidebar-sign-out-button"
            onClick={signOut}
          >
            <FontAwesomeIcon
              className="classify-sidebar-link--icon"
              icon={faSignOutAlt}
            />
            Signout
          </div>
        </div>
      </main>
    </div>
  );
};

export default NoAccess;
