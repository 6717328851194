import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "store";
import { lclId } from "utils/uuid";

import { INotification, INotificationConfig } from "./selectors";

export interface NotificationState {
  notificationsById: { [notificationId: string]: INotification };
}

const initialState: NotificationState = {
  notificationsById: {},
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showTransientNotification: (
      state,
      action: PayloadAction<INotification>
    ) => {
      state.notificationsById[action.payload.id] = action.payload;
    },
    hideTransientNotification: (state, action: PayloadAction<string>) => {
      delete state.notificationsById[action.payload];
    },
  },
});

export const { showTransientNotification, hideTransientNotification } =
  notificationsSlice.actions;

const configInvalid = (config: INotificationConfig) => {
  return !config.showDismiss && config.requireDismiss;
};

export const notify =
  (config: INotificationConfig, dismissAfter = 10000) =>
  async (dispatch: AppDispatch) => {
    if (configInvalid(config)) {
      console.warn(
        `[Classify.app.notify] Notification config invalid. Setting showDismiss to true.`,
        config
      );
      config.showDismiss = true;
    }

    const id = lclId();
    dispatch(showTransientNotification({ id, ...config }));
    if (!config.requireDismiss) {
      return new Promise((res) => {
        setTimeout(() => {
          dispatch(hideTransientNotification(id));
        }, dismissAfter);
        res(true);
      });
    } else {
      return Promise.resolve();
    }
  };

export default notificationsSlice.reducer;
