import { API } from "api";

export type ConnectorStatus = "ACTIVE" | "DISABLED" | "SUSPENDED";

export interface IConnector {
  id: string;
  status: ConnectorStatus;
  provider: string;
  external_account_id: string;
}

export const fetchAllConnectors = () => API.get("/api/v1/connectors/");

export const fetchUserConnectors = (
  id: string,
  limit: number = 10,
  offset: number = 0
) =>
  API.get(`/admin/connectors/?user_id=${id}&limit=${limit}&offset=${offset}`);

export const fetchConnectorById = (connector_id: string) =>
  API.get(`/admin/connectors/${connector_id}`);

export const fetchConnectorDocuments = (
  connector_id: string,
  user_id: string,
  limit: number = 10,
  offset: number = 0,
  sort_column: string = "",
  sort_dir: string = "desc"
) =>
  API.get(
    `/admin/connectors/${connector_id}/documents?user_id=${user_id}&limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_dir=${sort_dir}`
  );

export const kickoffHistoricalJob = (connector_id: string) =>
  API.post(
    `/admin/connectors/${connector_id}/kickoff-historical-job?days_back=14`
  );

export const postConnectorStatus = (connector_id: string, status: string) =>
  API.post(`/admin/connectors/${connector_id}/set-status/${status}`);

export const postScheduleRenew = (connector_id: string) =>
  API.post(`/admin/connectors/${connector_id}/schedule-renew?reset_gmail_history_id=true`);
