import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";
import { fetchActivity, fetchActivityEventsAndRelations } from "api/activities";
import {
  receiveActivities,
  receiveActivityEventsAndRelations,
} from "store/activities/slice";
import {
  getActivityById,
  getActivityEventsAndRelationsById,
} from "store/activities/selectors";

const ActivityDetail = ({ activityId }: { activityId: string }) => {
  const activity = useAppSelector((s) => getActivityById(s, activityId));
  const eventsAndRelations = useAppSelector((s) =>
    getActivityEventsAndRelationsById(s, activityId)
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingOtherInfo, setLoadingOtherInfo] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getActivity = async () => {
      setLoading(true);
      try {
        const { data } = await fetchActivity(activityId);
        dispatch(receiveActivities([data]));
      } catch (e) {
        setError("Failed to fetch activity");
      } finally {
        setLoading(false);
      }
    };

    if (!activity && !loading && !error && activityId) {
      getActivity();
    }
  }, [loading, activity, error, dispatch, activityId]);

  useEffect(() => {
    const getEventsAndRelations = async () => {
      setLoadingOtherInfo(true);
      try {
        const { data } = await fetchActivityEventsAndRelations(activityId);
        dispatch(
          receiveActivityEventsAndRelations({
            activityId,
            eventsAndRelations: data,
          })
        );
      } catch (e) {
        setError("Failed to fetch activity events");
      } finally {
        setLoadingOtherInfo(false);
      }
    };

    if (!eventsAndRelations && !loadingOtherInfo && !error && activityId) {
      getEventsAndRelations();
    }
  }, [loadingOtherInfo, eventsAndRelations, error, dispatch, activityId]);

  if (!activity || loading || loadingOtherInfo || !eventsAndRelations) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div>
        <h3>Raw data:</h3>
        <pre>{JSON.stringify(activity, null, 2)}</pre>
      </div>
      <div>
        <h3>Related Entities:</h3>
        <pre>
          {JSON.stringify((eventsAndRelations as any).relations, null, 2)}
        </pre>
      </div>
      <div>
        <h3>Related File Events:</h3>
        <pre>{JSON.stringify((eventsAndRelations as any).events, null, 2)}</pre>
      </div>
    </div>
  );
};

export default ActivityDetail;
