import axios from "axios";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "constants/resources";
import { getToken } from "utils/auth";
import { IFileEvent } from "store/documents/selectors";

export const API = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 second timeout
  headers: {
    "X-Classify-Version": "foobar",
    Authorization: `Bearer ${getToken()}`,
  },
});

export const api = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/admin",
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Events"],

  endpoints: (build) => ({
    fetchEvent: build.query<IFileEvent, string>({
      query: (id: string) => `/file_events/${id}`,
      providesTags: (_result, _error, id) => [{ type: "Events", id }],
    }),

    reingestFileEvent: build.mutation<{ requestId: string }, string>({
      query: (id: string) => ({
        url: `/file_events/${id}/reingest`,
        method: "POST",
      }),
      transformResponse: (_, meta) => ({
        requestId: meta?.response?.headers.get("x-request-id") || "unknown",
      }),
    }),
  }),
});
