import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TagsState {
  tagDetails: any | null;
  tagRelationDetails: any | null;
}

const initialState: TagsState = {
  tagDetails: null,
  tagRelationDetails: null,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    receiveTagDetails: (state, action: PayloadAction<any>) => {
      state.tagDetails = action.payload;
    },

    receiveTagRelationDetails: (state, action: PayloadAction<any>) => {
      state.tagRelationDetails = action.payload;
    },
  },
});

export const { receiveTagDetails, receiveTagRelationDetails } =
  tagsSlice.actions;
export default tagsSlice.reducer;
