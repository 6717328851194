import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { fetchTask } from "api/tasks";
import { useAppDispatch, useAppSelector } from "hooks";
import { getTaskDetail } from "store/tasks/selectors";
import { receiveTaskDetails } from "store/tasks/slice";

const TaskDetail = ({ taskId }: { taskId: string }) => {
  const task = useAppSelector(getTaskDetail);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getTask = async () => {
      setLoading(true);
      try {
        const { data } = await fetchTask(taskId);
        dispatch(receiveTaskDetails(data));
      } catch (e) {
        setError("Failed to fetch task");
      } finally {
        setLoading(false);
      }
    };

    if ((!task || task.id !== taskId) && !loading && !error && taskId) {
      getTask();
    }
  }, [loading, task, error, dispatch, taskId]);

  if (!task || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(task, null, 2)}</pre>
    </div>
  );
};

export default TaskDetail;
