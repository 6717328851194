import { RootState } from "store";

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface INotificationConfig {
  type: NotificationType;
  message: string;
  requireDismiss?: boolean;
  showDismiss?: boolean;
}

export interface INotification extends INotificationConfig {
  id: string;
}

const root = (state: RootState) => state.notifications;

const byId = (state: RootState) => root(state).notificationsById;

export const getAllNotifications = (state: RootState) =>
  Object.values(byId(state));
