import { configureStore, PayloadAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { DEBUG } from "constants/resources";
import connectorsReducer from "store/connectors/slice";
import documentsReducer from "store/documents/slice";
import userReducer from "store/user/slice";
import contactsReducer from "store/contacts/slice";
import notificationsReducer from "store/notifications/slice";
import rulesReducer from "store/rules/slice";
import contactListsReducer from "store/contact_lists/slice";
import linksReducer from "store/links/slice";
import tasksReducer from "store/tasks/slice";
import eventsReducer from "store/events/slice";
import activitiesReducer from "store/activities/slice";
import materialsReducer from "store/materials/slice";
import emailNotificationsReducer from "store/email_notifications/slice";
import notesReducer from "store/notes/slice";
import jobsReducer from "store/jobs/slice";
import tagsReducer from "store/tags/slice";
import { api } from "api";

const loggingMiddleware =
  () => (next: (action: PayloadAction) => void) => (action: PayloadAction) => {
    if (DEBUG) {
      console.info("[Classify.admin] applying action", action);
    }
    next(action);
  };

export const store = configureStore({
  reducer: {
    user: userReducer,
    connectors: connectorsReducer,
    documents: documentsReducer,
    contacts: contactsReducer,
    rules: rulesReducer,
    contactLists: contactListsReducer,
    notifications: notificationsReducer,
    links: linksReducer,
    tasks: tasksReducer,
    events: eventsReducer,
    activities: activitiesReducer,
    materials: materialsReducer,
    emailNotifications: emailNotificationsReducer,
    notes: notesReducer,
    jobs: jobsReducer,
    tags: tagsReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, loggingMiddleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

if (DEBUG) {
  (window as any).store = store;
}
