import { Button, Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";
import { getNoteById } from "store/notes/selectors";
import { receiveNotes } from "store/notes/slice";
import { fetchNote, hardDeleteNote } from "api/notes";

const NoteDetail = ({ noteId }: { noteId: string }) => {
  const note = useAppSelector((s) => getNoteById(s, noteId));
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingHardDelete, setLoadingHardDelete] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getNote = async () => {
      setLoading(true);
      try {
        const { data } = await fetchNote(noteId);
        dispatch(receiveNotes([data]));
      } catch (e) {
        setError("Failed to fetch note");
      } finally {
        setLoading(false);
      }
    };

    if (!note && !loading && !error && noteId) {
      getNote();
    }
  }, [loading, note, error, dispatch, noteId]);

  if (!note || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const hardDelete = async () => {
    if (loadingHardDelete) return;

    setLoadingHardDelete(true);

    try {
      await hardDeleteNote(noteId);
      setTimeout(
        () => (window.location.href = `/user-details/${note.created_by}`),
        400
      );
    } catch (err) {
      console.warn(err);
      setError("Failed to delete note");
    }
  };

  return (
    <div>
      <div>
        <Button
          onClick={hardDelete}
          intent="danger"
          isLoading={loadingHardDelete}
        >
          Hard delete
        </Button>
      </div>
      <div>
        <h3>Raw data:</h3>
        <pre>{JSON.stringify(note, null, 2)}</pre>
      </div>
    </div>
  );
};

export default NoteDetail;
