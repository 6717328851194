import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { fetchTag } from "api/tags";
import { useAppDispatch, useAppSelector } from "hooks";
import { getTagDetail } from "store/tags/selectors";
import { receiveTagDetails } from "store/tags/slice";

const TagDetail = ({ tagId }: { tagId: string }) => {
  const tag = useAppSelector(getTagDetail);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getTag = async () => {
      setLoading(true);
      try {
        const { data } = await fetchTag(tagId);
        dispatch(receiveTagDetails(data));
      } catch (e) {
        setError("Failed to fetch tag");
      } finally {
        setLoading(false);
      }
    };

    if ((!tag || tag.id !== tagId) && !loading && !error && tagId) {
      getTag();
    }
  }, [loading, tag, error, dispatch, tagId]);

  if (!tag || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(tag, null, 2)}</pre>
    </div>
  );
};

export default TagDetail;
