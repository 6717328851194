import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Material } from "./selectors";

export interface MaterialsState {
  userMaterials: { [userId: string]: Material[] };
  userMaterialsCount: { [userId: string]: number };
}

const initialState: MaterialsState = {
  userMaterials: {},
  userMaterialsCount: {},
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    receiveUserMaterials: (
      state,
      action: PayloadAction<{ userId: string; materials: Material[] }>
    ) => {
      state.userMaterials[action.payload.userId] = action.payload.materials;
    },
    receiveUserMaterialsCount: (
      state,
      action: PayloadAction<{ userId: string; count: number }>
    ) => {
      state.userMaterialsCount[action.payload.userId] = action.payload.count;
    },
  },
});

export const { receiveUserMaterials, receiveUserMaterialsCount } =
  materialsSlice.actions;
export default materialsSlice.reducer;
