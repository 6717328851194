import { API } from "api";

export const loginViaEmail = (email: string, password: string) => {
  const params = new URLSearchParams();
  params.append("username", email);
  params.append("password", password);
  return API.post("/api/v1/login/access-token", params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const newPasswordUpdate = (token: string, new_password: string) =>
  API.post("/api/v1/login/change-password", { token, new_password });

export const getGoogleOAuthURI = () =>
  API.get("/api/v1/users/oauth/google-redirect");
