export type WorkerJobStatus = "paused" | "in_progress" | "done" | "branch_done" | "rejected";

export const workerJobStatuses: Array<{ key: WorkerJobStatus; name: string }> =
  [
    { key: "paused", name: "Paused" },
    { key: "in_progress", name: "In progress" },
    { key: "done", name: "Done" },
    { key: "branch_done", name: "Branch Done" },
    { key: "rejected", name: "Rejected" },
  ];
