import { fetchTasksPage, resetTask } from "api/tasks";
import {
  Button,
  Spinner,
  Table,
  ChevronLeftIcon,
  ChevronRightIcon,
  SelectField,
  SideSheet,
  Card,
  Pane,
} from "evergreen-ui";
import { useState, useEffect } from "react";
import { NotificationType } from "store/notifications/selectors";
import { notify } from "store/notifications/slice";

const SystemViewTasks = () => {
  const [pageData, setPageData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [lastReqOffset, setLastReqOffset] = useState(0);
  const [status, setStatus] = useState("not_started");
  const [lastReqStatus, setLastReqStatus] = useState("not_started");
  const [selectedTask, setSelectedTask] = useState<any>(null);

  useEffect(() => {
    const requestNewPage = async () => {
      try {
        const { data } = await fetchTasksPage(offset, status);
        setPageData(data);
      } catch (e) {
        console.warn(e);
      } finally {
        setLastReqStatus(status);
        setLastReqOffset(offset);
        setLoading(false);
      }
    };

    if (
      !loading &&
      (offset !== lastReqOffset ||
        pageData === null ||
        status !== lastReqStatus)
    ) {
      requestNewPage();
    }
  }, [offset, loading, lastReqOffset, pageData, status, lastReqStatus]);

  // initial loader
  if ((loading && !pageData) || !pageData) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  const changeStatus = (e: any) => {
    setStatus(e.target.value);
    setOffset(0);
  };

  const reset = async () => {
    if (!selectedTask) {
      return;
    }

    setResetLoading(true);
    try {
      await resetTask(selectedTask.id);
      notify({
        type: NotificationType.SUCCESS,
        message: "Reset task",
      });
    } catch (err) {
      notify({
        type: NotificationType.ERROR,
        message: "Failed to reset task",
      });
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <div>
      <h3>Tasks</h3>
      <div>
        <Button
          iconBefore={ChevronLeftIcon}
          marginBottom={16}
          marginRight={16}
          disabled={offset === 0}
          onClick={() => setOffset(Math.max(0, offset - 10))}
        >
          Previous 10
        </Button>
        <Button
          iconAfter={ChevronRightIcon}
          marginBottom={16}
          disabled={pageData?.length !== 10}
          onClick={() => setOffset(offset + 10)}
        >
          Next 10
        </Button>
        <SelectField
          label="Task status"
          onChange={changeStatus}
          width={240}
          display="inline-block"
          marginLeft={16}
        >
          <option value="not_started" selected={status === "not_started"}>
            Not started
          </option>
          <option value="in_progress" selected={status === "in_progress"}>
            In progress
          </option>
          <option value="error" selected={status === "error"}>
            Failed
          </option>
          <option value="done" selected={status === "done"}>
            Finished
          </option>
        </SelectField>
      </div>
      <div className="recent-signups--page">Page {lastReqOffset / 10 + 1}</div>
      <Table width="80%">
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Scheduled for</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {(pageData || []).map((task: any) => (
            <Table.Row
              key={task.id}
              isSelectable
              onClick={() => setSelectedTask(task)}
            >
              <Table.TextCell>{task.name}</Table.TextCell>
              <Table.TextCell>
                {new Date(task.scheduled_for).toLocaleString("default", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <SideSheet
        isShown={!!selectedTask}
        onCloseComplete={() => setSelectedTask(null)}
      >
        <Pane padding={16}>
          <h2>Task details</h2>
          <Button
            disabled={
              !selectedTask || selectedTask.status !== "error" || resetLoading
            }
            isLoading={resetLoading}
            onClick={reset}
            marginTop={16}
            marginBottom={16}
          >
            Reset
          </Button>
          <Card
            overflow="scroll"
            padding={20}
            border="muted"
            elevation={0}
            height={540}
            alignItems="center"
            justifyContent="center"
            background="tint1"
          >
            <pre>{JSON.stringify(selectedTask, null, 2)}</pre>
          </Card>
        </Pane>
      </SideSheet>
    </div>
  );
};

export default SystemViewTasks;
