import { API } from "api";

export const fetchUserContactLists = (
  id: string,
  limit: number = 10,
  offset: number = 0
) =>
  API.get(
    `/admin/contact_lists/?user_id=${id}&limit=${limit}&offset=${offset}`
  );

export const fetchContactList = (id: string) =>
  API.get(`/admin/contact_lists/${id}`);
