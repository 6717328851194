import { useHistory } from "react-router";
import { Table } from "evergreen-ui";
import { EmailNotification } from "store/email_notifications/selectors";

const dateFmt = {
  month: "numeric",
  year: "2-digit",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
} as const;

const NotificationRow = ({ notification }: { notification: EmailNotification }) => {
  const history = useHistory();

  const { id, sent_at, actor, action_type, notification_platform, link } = notification;

  const updatedDateString = new Date(sent_at).toLocaleString(
    navigator.language,
    dateFmt
  );

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/notification-details/${id}`)}
    >
      <Table.TextCell>{id}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>{link?.url}</Table.TextCell>
      <Table.TextCell>{actor}</Table.TextCell>
      <Table.TextCell>{action_type}</Table.TextCell>
      <Table.TextCell>{notification_platform}</Table.TextCell>
    </Table.Row>
  );
};

const NotificationsTable = ({
  notifications,
}: {
  notifications: EmailNotification[];
}) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Sent At</Table.TextHeaderCell>
        <Table.TextHeaderCell>URL</Table.TextHeaderCell>
        <Table.TextHeaderCell>Actor</Table.TextHeaderCell>
        <Table.TextHeaderCell>Action Type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Platform</Table.TextHeaderCell>

      </Table.Head>
      <Table.Body>
        {notifications?.map((notification) => (
          <NotificationRow notification={notification} key={notification.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default NotificationsTable;
