import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser, ISignup } from "./selector";

export interface UserState {
  user: IUser | null;
  signups: {
    data: ISignup[] | null;
    pageOffset: number;
  };
  userDetails: IUser | null;
  forceNonSuperuser: boolean;
}

const initialState: UserState = {
  user: null,
  signups: { data: null, pageOffset: 0 },
  userDetails: null,
  forceNonSuperuser: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    receiveUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },

    toggleForceSuperuser: (state) => {
      state.forceNonSuperuser = !state.forceNonSuperuser;
    },

    receiveRecentSignups: (
      state,
      action: PayloadAction<{ data: ISignup[]; pageOffset: number }>
    ) => {
      state.signups = action.payload;
    },

    receiveUserDetails: (state, action: PayloadAction<IUser>) => {
      state.userDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  receiveUser,
  toggleForceSuperuser,
  receiveRecentSignups,
  receiveUserDetails,
} = userSlice.actions;
export default userSlice.reducer;
