import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConnector } from "./selectors";

export interface ConnectorState {
  connectorsById: { [connectorId: string]: IConnector };
  userConnectors: IConnector[] | null;
  searchedUserConnectorsById: { [connectorId: string]: IConnector };
  connectorDetails: IConnector | null;
}

const initialState: ConnectorState = {
  connectorsById: {},
  userConnectors: null,
  searchedUserConnectorsById: {},
  connectorDetails: null,
};

export const connectorsSlice = createSlice({
  name: "connectors",
  initialState,
  reducers: {
    receiveConnectors: (state, action: PayloadAction<any[]>) => {
      action.payload.forEach((connector) => {
        state.connectorsById[connector.id] = connector;
      });
    },

    receiveSearchedUserConnectors: (state, action: PayloadAction<any[]>) => {
      state.userConnectors = action.payload;
    },

    receiveSearchedUserConnectorsById: (
      state,
      action: PayloadAction<any[]>
    ) => {
      action.payload.forEach((connector) => {
        state.searchedUserConnectorsById[connector.id] = connector;
      });
    },

    receiveConnectorDetails: (state, action: PayloadAction<IConnector>) => {
      state.connectorDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  receiveConnectors,
  receiveSearchedUserConnectors,
  receiveSearchedUserConnectorsById,
  receiveConnectorDetails,
} = connectorsSlice.actions;
export default connectorsSlice.reducer;
