import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { fetchRecentSignups } from "api/user";
import { receiveConnectorDetails } from "store/connectors/slice";
import {
  IConnector,
  ConnectorStatus,
  fetchUserConnectors,
  postConnectorStatus,
} from "api/connectors";
import { useAppSelector, useAppDispatch } from "hooks";
import { receiveRecentSignups } from "store/user/slice";
import { useState, useEffect, ChangeEvent } from "react";
import { SelectField, Spinner, Table, Button, Badge } from "evergreen-ui";
import {
  getSignupsOffset,
  getSignupsOrderedByCreatedAt,
  ISignup,
} from "store/user/selector";

const ConnectorsUserRow = ({
  user,
  showOnlyStatusEqualTo,
}: {
  user: ISignup;
  showOnlyStatusEqualTo: string;
}) => {
  const [connectors, setConnectors] = useState<Array<IConnector> | undefined>();
  const [error, setError] = useState(false);
  const [refreshConnector, setRefreshConnector] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getConnector = async () => {
      try {
        const { data } = await fetchUserConnectors(user.id);
        setConnectors(data);
      } catch (err) {
        console.warn(err);
      }
    };

    if (user) {
      getConnector();
    }
  }, [user, user.id, refreshConnector]);

  const updateConnectors = async (
    connectorId: string,
    newConnectorStatus: string
  ) => {
    try {
      const { data } = await postConnectorStatus(
        connectorId,
        newConnectorStatus
      );
      dispatch(receiveConnectorDetails(data));
    } catch (e) {
      console.warn(e);
      setError(true);
    }
  };

  if (!connectors) {
    return null;
  }
  const enableButton = (connectorId: string) => (
    <Button
      size="large"
      intent={"success"}
      marginRight={5}
      onClick={() => {
        updateConnectors(connectorId, "ACTIVE");
        setRefreshConnector(!refreshConnector);
      }}
    >
      Enable
    </Button>
  );
  const disableButton = (connectorId: string) => (
    <Button
      size="large"
      intent={"danger"}
      marginRight={5}
      onClick={() => {
        updateConnectors(connectorId, "DISABLED");
        setRefreshConnector(!refreshConnector);
      }}
    >
      Disable
    </Button>
  );
  const suspendButton = (connectorId: string) => (
    <Button
      size="large"
      onClick={() => {
        updateConnectors(connectorId, "SUSPENDED");
        setRefreshConnector(!refreshConnector);
      }}
    >
      Suspend
    </Button>
  );

  const connectorActionButton = (connector: IConnector) =>
    error ? (
      <Badge
        color="blue"
        paddingTop={8}
        paddingBottom={8}
        paddingLeft={16}
        paddingRight={16}
        height={32}
      >
        Error
      </Badge>
    ) : (
      <div className="connectors--button">
        {connector.status === "DISABLED" ? null : disableButton(connector.id)}
        {connector.status === "SUSPENDED" ? null : suspendButton(connector.id)}
        {connector.status === "ACTIVE" ? null : enableButton(connector.id)}
      </div>
    );

  return (
    <>
      {connectors.map((connector) =>
        connector.status === showOnlyStatusEqualTo ? (
          <Table.Row
            height={80}
            isSelectable
            key={user.id + connector.external_account_id + connector.status}
          >
            <Table.TextCell
              onClick={() => history.push(`/user-details/${user.id}`)}
            >
              {user.full_name}
            </Table.TextCell>
            <Table.TextCell
              onClick={() => history.push(`/user-details/${user.id}`)}
            >
              {user.email}
            </Table.TextCell>
            <Table.TextCell
              onClick={() => history.push(`/connector-details/${connector.id}`)}
            >
              {connector.provider}
            </Table.TextCell>
            <Table.TextCell
              onClick={() => history.push(`/connector-details/${connector.id}`)}
            >
              {connector.status}
            </Table.TextCell>
            <Table.TextCell>{connectorActionButton(connector)}</Table.TextCell>
          </Table.Row>
        ) : null
      )}
    </>
  );
};

const fetchSize = 10;

const SystemViewConnectors = () => {
  const [selectedStatus, setSelectedStatus] =
    useState<ConnectorStatus>("SUSPENDED");
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const signupsPageOffset = useAppSelector(getSignupsOffset);
  const recentSignups = useAppSelector(getSignupsOrderedByCreatedAt);
  const [users, setUsers] = useState(recentSignups || []);
  const [allUsersFetched, setAllUsersFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadRecentSignups = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchRecentSignups(fetchSize, offset);
        setUsers([...users, ...data]);
        dispatch(receiveRecentSignups({ data, pageOffset: offset }));
        if (data.length < fetchSize) {
          setAllUsersFetched(true);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && (!recentSignups || offset !== signupsPageOffset)) {
      loadRecentSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, dispatch, recentSignups, offset, signupsPageOffset]);

  return (
    <div>
      <div>
        <SelectField
          label="Status"
          width={240}
          value={selectedStatus}
          display="inline-block"
          marginRight={20}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelectedStatus(e.target.value as ConnectorStatus)
          }
        >
          <option value="SUSPENDED">Suspended</option>
          <option value="ACTIVE">Active</option>
          <option value="DISABLED">Disabled</option>
        </SelectField>
        <Button
          disabled={allUsersFetched}
          onClick={() => setOffset(offset + fetchSize)}
        >
          Check next {fetchSize} users
        </Button>
      </div>
      {isLoading && (
        <div>
          Loading <Spinner />
        </div>
      )}
      {!!recentSignups && (
        <div className="connectors--page">
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Name</Table.TextHeaderCell>
              <Table.TextHeaderCell>Email</Table.TextHeaderCell>
              <Table.TextHeaderCell>Connector</Table.TextHeaderCell>
              <Table.TextHeaderCell>Connector Status</Table.TextHeaderCell>
              <Table.TextHeaderCell>Action</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {users.map((user) => (
                <ConnectorsUserRow
                  key={user.id}
                  user={user}
                  showOnlyStatusEqualTo={selectedStatus}
                />
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default SystemViewConnectors;
