import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RulesState {
  userRules: any[] | null;
  ruleDetails: any | null;
}

const initialState: RulesState = {
  userRules: null,
  ruleDetails: null,
};

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    receiveUserRules: (state, action: PayloadAction<any[]>) => {
      state.userRules = action.payload;
    },

    receiveRuleDetails: (state, action: PayloadAction<any>) => {
      state.ruleDetails = action.payload;
    },
  },
});

export const { receiveUserRules, receiveRuleDetails } = rulesSlice.actions;
export default rulesSlice.reducer;
