import { Dialog, Switch } from "evergreen-ui";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";

import { putSetting } from "api/user";
import { useAppDispatch } from "hooks";
import { receiveUserDetails } from "store/user/slice";
import { IUser } from "store/user/selector";

import "./style.css";

type ObjectType = Record<string, number | boolean | string>;

const defaultTimelineSettings = {
  showMe: true,
  includeExternal: true,
  includeInternal: true,
  orderByGroupSize: false,
  includeQuery: false,
  query: undefined,
  minGroupSize: 2,
};

const TimelineSettingsKey = "timeline.settings";

const UserSettingsDialog = ({
  visible = false,
  current = [],
  onClose,
  userId,
}: {
  visible?: boolean;
  current: IUser["properties"];
  onClose: () => void;
  userId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [settingValue, setSettingValue] = useState("");
  const [selectedSetting, setSelectedSetting] = useState<null | {
    label: string;
    value: string;
  }>(null);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const existingValues = current
    ?.filter((x) => x.namespace === "SETTINGS")
    .map((x) => ({ label: x.name, value: `${x.value}` }));

  const missingTimelineSettings =
    existingValues.filter((x) => x.label === TimelineSettingsKey).length === 0;
  if (missingTimelineSettings) {
    existingValues.push({
      label: TimelineSettingsKey,
      value: JSON.stringify(defaultTimelineSettings),
    });
  }
  let objectValue: ObjectType | undefined = undefined;
  let booleanValue: boolean | undefined = undefined;
  try {
    objectValue = JSON.parse(settingValue);
    if (typeof objectValue !== "object") {
      booleanValue = objectValue;
      objectValue = undefined;
    }
  } catch {
    objectValue = undefined;
    booleanValue = undefined;
  }

  const [objectEditingValue, setObjectEditingValue] = useState<
    ObjectType | undefined
  >(undefined);

  const updateSetting = async () => {
    if (selectedSetting === null) {
      setError("You must select a setting");
      return;
    }

    setLoading(true);
    try {
      const { data } = await putSetting(
        userId,
        selectedSetting.label,
        objectValue
          ? JSON.stringify(objectEditingValue ?? objectValue)
          : settingValue
      );
      dispatch(receiveUserDetails(data));
      setSettingValue(
        objectValue ? JSON.stringify(objectEditingValue) : settingValue
      );
      setSelectedSetting({ label: selectedSetting.label, value: settingValue });
    } catch (e) {
      console.warn(e);
      setError("Failed to update the user's settings");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      isShown={visible}
      title="Change user's settings"
      onCloseComplete={onClose}
      confirmLabel="Submit"
      minHeightContent="50vh"
      onConfirm={updateSetting}
      isConfirmLoading={loading}
      isConfirmDisabled={!selectedSetting}
    >
      <div>
        <label className="user-setting-dialog--label">Setting</label>
        <CreatableSelect
          onChange={(x) => {
            setSelectedSetting(x);
            setSettingValue(x?.value ?? "");
          }}
          value={selectedSetting}
          options={existingValues}
        />
      </div>
      {booleanValue !== undefined && (
        <BooleanSetting
          current={selectedSetting?.value === "true"}
          currentSelected={booleanValue}
          onChange={() => setSettingValue(booleanValue ? "false" : "true")}
        />
      )}
      {objectValue && (
        <>
          {missingTimelineSettings ? null : (
            <div className="user-setting-dialog--row">
              <label className="user-setting-dialog--label">
                Current state
              </label>
              <span className="user-setting-dialog--label">
                <pre>
                  <code>{JSON.stringify(objectValue, null, 2)}</code>
                </pre>
              </span>
            </div>
          )}
          <label className="user-setting-dialog--label">Change to</label>
          {Object.entries(objectEditingValue ?? objectValue ?? {}).map(
            ([key, value]) => {
              switch (typeof value) {
                case "boolean":
                  return (
                    <BooleanSetting
                      key={key}
                      label={key}
                      current={Boolean((objectValue ?? {})[key])}
                      currentSelected={value}
                      onChange={() => {
                        setObjectEditingValue({
                          ...objectValue,
                          ...(objectEditingValue ?? {}),
                          [key]: !value,
                        });
                      }}
                    />
                  );
              }

              return (
                <StringSetting
                  key={key}
                  label={key}
                  current={(objectValue ?? {})[key].toString()}
                  currentSelected={value.toString()}
                  onChange={(x) => {
                    setObjectEditingValue({
                      ...objectValue,
                      ...(objectEditingValue ?? {}),
                      [key]: x,
                    });
                  }}
                />
              );
            }
          )}
        </>
      )}
      {booleanValue === undefined &&
        objectValue === undefined &&
        selectedSetting?.value && (
          <StringSetting
            current={selectedSetting?.value}
            currentSelected={settingValue}
            onChange={setSettingValue}
          />
        )}
      {error}
    </Dialog>
  );
};

const StringSetting = ({
  current,
  currentSelected,
  onChange,
  label,
}: {
  current: string;
  currentSelected: string;
  onChange: (newValue: string) => void;
  label?: string;
}) => {
  return (
    <>
      <div className="user-setting-dialog--row">
        <label className="user-setting-dialog--label">
          {label ?? "Current state"}
        </label>
        <span className="user-setting-dialog--label">{current}</span>
      </div>
      <div className="user-setting-dialog--row">
        <label className="user-setting-dialog--label">
          {label ? "" : "Change to"}
        </label>
        <input
          value={currentSelected}
          onChange={(x) => {
            onChange(x.target.value);
          }}
        />
      </div>
    </>
  );
};

const BooleanSetting = ({
  current,
  currentSelected,
  onChange,
  label,
}: {
  current: boolean;
  currentSelected: boolean;
  onChange: (newValue: boolean) => void;
  label?: string;
}) => {
  return (
    <>
      <div className="user-setting-dialog--row">
        <label className="user-setting-dialog--label">
          {label ?? "Current state"}
        </label>
        <span className="user-setting-dialog--label">
          <Switch
            className="user-setting-dialog--switch"
            height={24}
            checked={current}
          />
          <div className="user-setting-dialog-switch--help">
            ({current ? "enabled" : "disabled"})
          </div>
        </span>
      </div>
      <div className="user-setting-dialog--row">
        <label className="user-setting-dialog--label">
          {label ? "" : "Change to"}
        </label>
        <Switch
          className="user-setting-dialog--switch"
          height={24}
          checked={currentSelected}
          onChange={() => onChange(!currentSelected)}
        />
      </div>
    </>
  );
};

export default UserSettingsDialog;
