import { GateOptions } from "constants/gates";
import { patchGate } from "api/user";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { fetchRecentSignups } from "api/user";
import { IGate, fetchGate } from "api/gates";
import { useAppSelector, useAppDispatch } from "hooks";
import { receiveRecentSignups, receiveUserDetails } from "store/user/slice";
import { useState, useEffect, ChangeEvent } from "react";
import { SelectField, Spinner, Table, Button, Badge } from "evergreen-ui";
import {
  getSignupsOffset,
  getSignupsOrderedByCreatedAt,
  ISignup,
} from "store/user/selector";

const GateUserRow = ({
  user,
  gateName,
  showOnlyStatusEqualTo,
}: {
  user: ISignup;
  gateName: string;
  showOnlyStatusEqualTo: boolean | undefined;
}) => {
  const [gate, setGate] = useState<IGate | undefined>();
  const [error, setError] = useState(false);
  const [refreshGate, setRefreshGate] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getGate = async () => {
      try {
        const { data } = await fetchGate(user.id, gateName);
        setGate(data);
      } catch (err) {
        console.warn(err);
      }
    };

    if (user && gateName) {
      getGate();
    }
  }, [user, user.id, gateName, refreshGate]);

  const updateGate = async (gate: IGate) => {
    try {
      const { data } = await patchGate(
        user.id,
        gate.gate_name,
        !gate.gate_value
      );
      dispatch(receiveUserDetails(data));
    } catch (e) {
      console.warn(e);
      setError(true);
    }
  };

  if (
    !gate ||
    (showOnlyStatusEqualTo && !gate.gate_value) ||
    (showOnlyStatusEqualTo === false && gate.gate_value)
  ) {
    return null;
  }

  const gateActionButton = error ? (
    <Badge
      color="blue"
      paddingTop={8}
      paddingBottom={8}
      paddingLeft={16}
      paddingRight={16}
      height={32}
    >
      Error
    </Badge>
  ) : (
    <Button
      size="large"
      intent={gate.gate_value ? "danger" : "success"}
      onClick={() => {
        updateGate(gate);
        setRefreshGate(!refreshGate);
      }}
    >
      {gate.gate_value ? "Disable Gate" : "Enable Gate"}
    </Button>
  );

  return (
    <Table.Row isSelectable key={user.id + gate.gate_value}>
      <Table.TextCell onClick={() => history.push(`/user-details/${user.id}`)}>
        {user.full_name}
      </Table.TextCell>
      <Table.TextCell onClick={() => history.push(`/user-details/${user.id}`)}>
        {user.email}
      </Table.TextCell>
      <Table.TextCell onClick={() => history.push(`/user-details/${user.id}`)}>
        {user.status}
      </Table.TextCell>
      <Table.TextCell>{gateActionButton}</Table.TextCell>
    </Table.Row>
  );
};

const fetchSize = 10;
type SelectedStatusType = "all" | "enabled" | "disabled";

const SystemViewGates = () => {
  const [selectedGate, setSelectedGate] = useState(GateOptions[0].value);
  const [selectedStatus, setSelectedStatus] =
    useState<SelectedStatusType>("all");
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const signupsPageOffset = useAppSelector(getSignupsOffset);
  const recentSignups = useAppSelector(getSignupsOrderedByCreatedAt);
  const [users, setUsers] = useState(recentSignups || []);
  const [allUsersFetched, setAllUsersFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadRecentSignups = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchRecentSignups(fetchSize, offset);
        setUsers([...users, ...data]);
        dispatch(receiveRecentSignups({ data, pageOffset: offset }));
        if (data.length < fetchSize) {
          setAllUsersFetched(true);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isLoading && (!recentSignups || offset !== signupsPageOffset)) {
      loadRecentSignups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, dispatch, recentSignups, offset, signupsPageOffset]);

  return (
    <div>
      <div>
        <SelectField
          label="Gates"
          width={240}
          value={selectedGate}
          display="inline-block"
          marginRight={20}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelectedGate(e.target.value)
          }
        >
          {GateOptions.map((gate) => (
            <option key={gate.value} value={gate.value}>
              {gate.label}
            </option>
          ))}
        </SelectField>
        <SelectField
          label="Status"
          width={240}
          value={selectedStatus}
          display="inline-block"
          marginRight={20}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelectedStatus(e.target.value as SelectedStatusType)
          }
        >
          <option value="all">All</option>
          <option value="enabled">Enabled</option>
          <option value="disabled">Disabled</option>
        </SelectField>
        <Button
          disabled={allUsersFetched}
          onClick={() => setOffset(offset + fetchSize)}
        >
          Check next {fetchSize} users
        </Button>
      </div>
      {isLoading && (
        <div>
          Loading <Spinner />
        </div>
      )}
      {!!recentSignups && (
        <div className="gates--page">
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Name</Table.TextHeaderCell>
              <Table.TextHeaderCell>Email</Table.TextHeaderCell>
              <Table.TextHeaderCell>Status</Table.TextHeaderCell>
              <Table.TextHeaderCell>Action</Table.TextHeaderCell>
            </Table.Head>
            {selectedGate ? (
              <Table.Body>
                {users.map((user) => (
                  <GateUserRow
                    key={user.id + selectedGate}
                    user={user}
                    gateName={selectedGate}
                    showOnlyStatusEqualTo={
                      selectedStatus === "all"
                        ? undefined
                        : selectedStatus === "enabled"
                    }
                  />
                ))}
              </Table.Body>
            ) : null}
          </Table>
        </div>
      )}
    </div>
  );
};

export default SystemViewGates;
