import { RootState } from "store";
import { sort } from "ramda";

interface IProperty {
  namespace: string;
  name: string;
  value: any;
}

const EMPTY_PROPERTY = {
  namespace: "*",
  name: "NOT_FOUND",
  value: undefined,
};
interface IOrg {
  id: string;
  properties: IProperty[];
}

export interface IUser {
  id: string;
  full_name: string;
  email: string;
  is_superuser: boolean;
  org_id: string;
  status: string;
  properties: IProperty[];
  org: IOrg;
}

export interface ISignup {
  id: string;
  full_name: string;
  email: string;
  status: string;
  created_at: string;
  properties: IProperty[];
}

const root = (state: RootState) => state.user;

export const getCurrentUser = (state: RootState): IUser => root(state).user!;
export const getUserDetails = (state: RootState): IUser =>
  root(state).userDetails!;

const getSignups = (state: RootState) => root(state).signups;
export const getSignupsOffset = (state: RootState) =>
  getSignups(state).pageOffset;
const getRecentSignups = (state: RootState) => getSignups(state).data;
export const getSignupsOrderedByCreatedAt = (state: RootState) => {
  const signups = getRecentSignups(state);

  if (!signups) {
    return null;
  }

  return sort(
    (signup1, signup2) =>
      Date.parse(signup2.created_at) - Date.parse(signup1.created_at),
    signups
  );
};

const getCurrentOrg = (state: RootState) => getCurrentUser(state).org;
const getOrgProps = (state: RootState) => getCurrentOrg(state).properties;
const getOrgPropByName = (state: RootState, name: string): IProperty =>
  getOrgProps(state).find((p) => p.name === name) || EMPTY_PROPERTY;
export const getOrgName = (state: RootState): string =>
  (getOrgPropByName(state, "name").value as string) || "";

export const getRawIsSuperuser = (state: RootState) =>
  (getCurrentUser(state) || {}).is_superuser;

export const getIsSuperuser = (state: RootState) =>
  getRawIsSuperuser(state) && !root(state).forceNonSuperuser;
export const getForcedNormalUser = (state: RootState) =>
  root(state).forceNonSuperuser;
