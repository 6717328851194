import { useParams } from "react-router";

import Sidebar from "components/Sidebar";
import WorkerJobDetail from "components/WorkerJobDetail";

const WorkerJobDetailView = () => {
  const params = useParams<{ id: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>Worker Job</h1>
        <WorkerJobDetail workerJobId={params.id} />
      </div>
    </div>
  );
};

export default WorkerJobDetailView;
