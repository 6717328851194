import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";
import { getJobDetail } from "store/jobs/selectors";
import { receiveJobDetails } from "store/jobs/slice";
import { fetchWorkerJob } from "api/jobs";

const WorkerJobDetail = ({ workerJobId }: { workerJobId: string }) => {
  const job = useAppSelector(getJobDetail);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getJob = async () => {
      setLoading(true);
      try {
        const { data } = await fetchWorkerJob(workerJobId);
        dispatch(receiveJobDetails(data));
      } catch (e) {
        setError("Failed to fetch job");
      } finally {
        setLoading(false);
      }
    };

    if ((!job || job.id !== workerJobId) && !loading && !error && workerJobId) {
      getJob();
    }
  }, [loading, job, error, dispatch, workerJobId]);

  if (!job || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(job, null, 2)}</pre>
    </div>
  );
};

export default WorkerJobDetail;
