import { RootState } from "store";

export interface IActivityMessage {
  sent_at: string;
  sender: {
    name: string;
    contact_id: string; // may be null
    contact_handle_id: string;
  };
  content: {
    text: string;
  };
}

interface IActivityData {
  actor: {
    contact_id: string;
    contact_handle_id: string;
  };
  connector: {
    id: string;
    provider: string;
  };
}

export interface INewFileGdriveActivityData extends IActivityData {
  document_id: string;
  gdrive_file: {
    authuser: string;
    file_id: string;
  };
}

export interface INewFileGmailActivityData extends IActivityData {
  document_ids: string[];
  gmail_message: {
    authuser: string;
    message_id: string;
    thread_id: string;
    snippet: string;
    subject: string;
  };
  // deprecated, but kept for older events
  // that don't have gmail_message
  gmail_file_share: {
    authuser: string;
    message_id: string;
    thread_id: string;
    snippet: string;
    subject: string;
  };
  messages: IActivityMessage[];
}

export interface INewFileSlackActivityData extends IActivityData {
  document_id: string;
  slack_file_share: {
    file_id: string;
    channel_id: string;
    channel_name: string;
    channel_space: string;
    slack_user_id: string;
  };
  messages: IActivityMessage[];
}

export interface IActivity {
  id: string;
  user_id: string;
  timestamp: string;
  updated_at: string;
  deleted_at: string;
  type: string;
  meta: any;
  data:
    | INewFileGdriveActivityData
    | INewFileGmailActivityData
    | INewFileSlackActivityData;
  dedup_key: string;
}

const root = (state: RootState) => state.activities;
const activityById = (state: RootState) => root(state).activitiesById;
const activityEventsAndRelations = (state: RootState) =>
  root(state).activityEventsAndRelationsById;
const userActivity = (state: RootState, userId: string) =>
  root(state).userActivities[userId] || [];

export const getActivityById = (state: RootState, id: string) =>
  activityById(state)[id];

export const getActivityEventsAndRelationsById = (
  state: RootState,
  id: string
) => activityEventsAndRelations(state)[id];

export const getUserActivities = (state: RootState, userId: string) =>
  userActivity(state, userId)
    .map((actId) => getActivityById(state, actId))
    .filter((d) => !!d);
