import { RootState } from "store";

export type EmailNotificationType = "LINK" | "DOCUMENT";

type EmailNotificationLink = {
  connector_id: string | null;
  url: string;
  user_id: string | null;
  namespace: string;
  title: string;
  description: null;
  data: {
    title: string;
  };
};

export type EmailNotification = {
  id: string;
  user_id: string;
  sent_at: string;
  timestamp: string | null;
  actor: string | null;
  message: string | null;
  action_type: string | null;
  event_id: string | null;
  entity_id: string;
  entity_type: "LINK";
  meta: {
    notification_platform: string;
  };
  link?: EmailNotificationLink;
  notification_platform: string;
  actor_type: string;
};

const root = (state: RootState) => state.emailNotifications;
const emailNotifications = (state: RootState, userId: string) =>
  root(state).userNotifications[userId] || [];

export const getUserNotifications = (state: RootState, userId: string) =>
  emailNotifications(state, userId);

export const getUserNotificationsCount = (state: RootState, userId: string) =>
  root(state).userNotificationsCount[userId] || 0;
