import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Spinner } from "evergreen-ui";

import Sidebar from "components/Sidebar";
import DocumentTable from "components/DocumentTable";
import LinkTable from "components/LinkTable";

import { fetchMaterial } from "api/materials";
import { useAppSelector } from "hooks";
import { getUserMaterials, Material } from "store/materials/selectors";
import { notify } from "store/notifications/slice";
import { NotificationType } from "store/notifications/selectors";

const dateFmt: any = {
  month: "numeric",
  year: "2-digit",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
} as const;

const MaterialDetailView = ({ materialId }: { materialId?: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [materialDetails, setMaterialDetails] = useState<
    Material | undefined
  >();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await fetchMaterial(materialId!);
        setMaterialDetails(data);
      } catch (e) {
        setError(true);
        notify({
          message: "Failed to fetch material.",
          type: NotificationType.ERROR,
        })(dispatch);
      } finally {
        setLoading(false);
      }
    };
    if (
      materialId &&
      (!materialDetails || materialId !== materialDetails.id) &&
      !loading &&
      !error
    ) {
      fetch();
    }
  }, [materialId, materialDetails, dispatch, loading, error]);

  const userMaterials = useAppSelector((s) =>
    getUserMaterials(s, materialDetails?.user_id || "")
  );

  if (loading) {
    return (
      <div>
        Loading
        <Spinner />
      </div>
    );
  }

  if (error || !materialDetails) {
    return <div>Could not load the material</div>;
  }

  const { title, user_id, last_activity_at, url, internal_type } =
    materialDetails;
  const additionalMaterialDetails = userMaterials.find(
    (x) => x.id === materialDetails.id
  );
  const entity = materialDetails.entity || additionalMaterialDetails?.entity;
  const activity =
    materialDetails.activity || additionalMaterialDetails?.activity;
  const lastActivityDate = new Date(last_activity_at || "").toLocaleString(
    navigator.language,
    dateFmt
  );

  return (
    <div>
      <h3>{title}</h3>
      <Link to={`/user-details/${user_id}`}>View owner</Link>
      <div>
        <Table width={1000} marginY={24}>
          <Table.Head>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>URL</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Activity At</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TextCell>{title}</Table.TextCell>
              <Table.TextCell>{url}</Table.TextCell>
              <Table.TextCell>{internal_type}</Table.TextCell>
              <Table.TextCell>{lastActivityDate}</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <h3>Related entity</h3>
      {internal_type === "LINK" && !!entity && (
        <>
          <Link to={`/link-details/${entity.id}`}>View link</Link>
          <LinkTable links={[entity]} />
        </>
      )}
      {internal_type === "DOCUMENT" && !!entity && (
        <>
          <Link to={`/document-details/${entity?.id}`}>View document</Link>
          <DocumentTable documents={[entity]} />
        </>
      )}

      <h3>Latest activity on the material</h3>
      <Table width={1000} marginY={24}>
        <Table.Head>
          <Table.TextHeaderCell>Action</Table.TextHeaderCell>
          <Table.TextHeaderCell>Time</Table.TextHeaderCell>
          <Table.TextHeaderCell>Source</Table.TextHeaderCell>
          <Table.TextHeaderCell>Message</Table.TextHeaderCell>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        </Table.Head>
        {activity.length === 0 && <h4>No activity</h4>}
        <Table.Body>
          {activity.map((activity, i) => (
            <Table.Row key={`${activity.when}${i}`}>
              <Table.TextCell>{activity.action}</Table.TextCell>
              <Table.TextCell>
                {new Date(`${activity.when}`).toLocaleString(
                  navigator.language,
                  dateFmt
                )}
              </Table.TextCell>
              <Table.TextCell>{activity.source}</Table.TextCell>
              <Table.TextCell>{activity.message}</Table.TextCell>
              <Table.TextCell>{activity.name}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const MaterialDetailsContainer = () => {
  const params = useParams<{ id?: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <MaterialDetailView materialId={params.id} />
      </div>
    </div>
  );
};

export default MaterialDetailsContainer;
