import { useEffect, useState } from "react";
import { CornerDialog } from "evergreen-ui";

import { usePrevious } from "hooks";

let extIntervalId: null | ReturnType<typeof setInterval> = null;

const getChunkId = async (): Promise<string> => {
  const reg = new RegExp(
    '<script src="/static/js/main.(?<chunk>[0-9a-f]+).chunk.js"'
  );
  try {
    const res = await fetch("/");
    const htmlBody = await res.text();
    return htmlBody.match(reg)?.groups?.chunk || "";
  } catch (err) {
    return "";
  }
};

const AppUpdateContainer = () => {
  const [latestChunk, setLatestChunk] = useState<null | string>(null);
  const [updateIsAvailable, setUpdateIsAvailable] = useState(false);
  const [userDismissed, setUserDismissed] = useState(false);
  const previousChunk = usePrevious(latestChunk);

  // check to see if there is an update
  useEffect(() => {
    if (!updateIsAvailable && latestChunk !== null) {
      setUpdateIsAvailable(
        previousChunk !== null && latestChunk !== previousChunk
      );
    }
  }, [latestChunk, previousChunk, updateIsAvailable]);

  // start interval and clear on unmount
  useEffect(() => {
    extIntervalId = setInterval(async () => {
      const chunk = await getChunkId();
      if (chunk) {
        setLatestChunk(chunk);
      }
    }, 15000);

    return () => {
      if (extIntervalId) {
        clearInterval(extIntervalId);
      }
    };
  }, []);

  // clear the interval if the user dismissed
  useEffect(() => {
    if (extIntervalId && userDismissed) {
      clearInterval(extIntervalId);
      extIntervalId = null;
    }
  }, [userDismissed]);

  if (userDismissed) {
    return null;
  }

  return (
    <CornerDialog
      isShown={updateIsAvailable && !userDismissed}
      onConfirm={() => window.location.reload()}
      onCloseComplete={() => setUserDismissed(true)}
      title="A new update is available"
      confirmLabel="Reload"
      hasCancel={false}
    >
      <div>Reload to get the latest version of Classify</div>
    </CornerDialog>
  );
};

export default AppUpdateContainer;
