import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IContact } from "./selectors";

export interface ContactState {
  contactDetails: IContact | null;
  userContacts: null | IContact[];
}

const initialState: ContactState = {
  contactDetails: null,
  userContacts: null,
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    receiveContactDetails: (state, action: PayloadAction<IContact>) => {
      state.contactDetails = action.payload;
    },

    receiveSearchedUserContacts: (state, action: PayloadAction<IContact[]>) => {
      state.userContacts = action.payload;
    },
  },
});

export const { receiveContactDetails, receiveSearchedUserContacts } =
  contactsSlice.actions;
export default contactsSlice.reducer;
