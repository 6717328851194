import * as ramda from "ramda";

import { RootState } from "store";

const root = (state: RootState) => state.documents;

const getDocumentsById = (state: RootState) => root(state).documentsById;
export const getDocumentById = (state: RootState, documentId: string) =>
  (getDocumentsById(state) || {})[documentId];
export const getSearchedUserDocuments = (state: RootState) =>
  root(state).userDocuments;
export const getConnectorDocuments = (state: RootState) =>
  root(state).connectorDocuments;
export const getDocumentDetails = (state: RootState) =>
  root(state).documentDetails;
export const getUserDocsAmountPerConn = (state: RootState) =>
  root(state).userDocsAmountPerConn;

const sidebar = (state: RootState) => root(state).sidebar;

export const getSidebarIsOpen = (state: RootState) => sidebar(state).isOpen;
export const getSidebarDocumentId = (state: RootState) =>
  sidebar(state).documentId;
export const getSidebarDocument = (state: RootState) =>
  getDocumentById(state, getSidebarDocumentId(state) || "");

export const getDocumentsNeverLoaded = (state: RootState) =>
  getDocumentsById(state) === null;

const getDocumentsOrderedByCreatedAt = (
  state: RootState,
  starred?: boolean
) => {
  const docs = getDocumentsById(state);

  if (docs === null) {
    return [];
  }

  const filteredDocs = starred
    ? Object.values(docs).filter((doc) => doc.star)
    : Object.values(docs);

  return ramda.sort(
    (doc1, doc2) => Date.parse(doc2.created_at) - Date.parse(doc1.created_at),
    filteredDocs
  );
};

export const getOldestDocumentTimestamp = (state: RootState) => {
  const oldest = root(state).oldestTimelineFetch;
  return oldest && oldest.toISOString().replace("+00:00", "Z");
};

// inefficient to always sort but we can fix later
export const getHasNoDocuments = (state: RootState) =>
  getDocumentsOrderedByCreatedAt(state).length === 0;

interface IContact {
  name: string;
  handle: string;
  source: string;
}

export interface IMessageContext {
  sender: IContact;
  recipients: IContact[];
  content: string;
  sent_at: string; // ISO8601 datetime string
  thread_id: string;
}

export interface IFileEvent {
  connector_id: string;
  dedup_key: string;
  id: string;
  user_id: string;
  timestamp: string;
  meta: any;
  event_data: any;
}

export interface ISender {
  contact_handle_id: string;
  subject: string;
  shared_at: string;
}

export interface IFileEventRelations {
  document_id: string;
  file_event_id: string;
}

export interface IDocument {
  id: string;
  connector_id: string;
  size_bytes: number;
  filename: string;
  mimetype: string;
  star: boolean;
  created_at: string;
  updated_at: string;
  location: string;
  file_events: IFileEvent[];
  message_context: IMessageContext[] | null;
  user_id: string;
  sender: ISender[];
  file_event_relations: IFileEventRelations[];
}

interface IDateAndDocuments {
  date: Date;
  documents: IDocument[]; // TODO: fill out interface
}

export const getDocumentDateGroups = (
  state: RootState,
  starred?: boolean
): IDateAndDocuments[] => {
  const orderedDocs = getDocumentsOrderedByCreatedAt(state, starred);

  const groups: IDateAndDocuments[] = [];

  if (orderedDocs.length === 0) {
    return groups;
  }

  let currentDate = new Date(orderedDocs[0].created_at);
  let currentGroup: any[] = [];

  orderedDocs.forEach((document) => {
    const docDate = new Date(document.created_at);

    if (docDate.getDate() !== currentDate.getDate()) {
      groups.push({
        date: currentDate,
        documents: currentGroup,
      });
      currentDate = docDate;
      currentGroup = [];
    }

    currentGroup.push(document);
  });

  if (currentGroup.length) {
    groups.push({
      date: currentDate,
      documents: currentGroup,
    });
  }

  return groups;
};
