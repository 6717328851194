import { Pane, Tab, Tablist } from "evergreen-ui";
import { Route, Switch, useHistory, useLocation } from "react-router";

import Sidebar from "components/Sidebar";

import "./style.css";
import SystemDataView from "./data";
import SystemViewTasks from "./tasks";
import SystemViewDeploy from "./deploy";
import SystemViewJobs from "./jobs";
import SystemViewGates from "./gates";
import SystemViewConnectors from "./connectors";

const Tabs = [
  { name: "Data", path: "/system/data" },
  { name: "Scheduled Tasks", path: "/system/tasks" },
  { name: "Deploy", path: "/system/deploy" },
  { name: "Worker Jobs", path: "/system/jobs" },
  { name: "Gates", path: "/system/gates" },
  { name: "Connectors", path: "/system/connectors" },
];

const SystemView = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>System</h1>
        <Pane display="flex">
          <Tablist display="flex" flexDirection="column" alignItems="start">
            {Tabs.map(({ name, path }) => (
              <Tab
                width="100%"
                marginBottom={12}
                key={name}
                id={name}
                onSelect={() => history.push(path)}
                isSelected={location.pathname.startsWith(path)}
              >
                {name}
              </Tab>
            ))}
          </Tablist>
          <Pane flex="1" marginLeft={32}>
            <Switch>
              <Route path="/system/data" component={SystemDataView} />
              <Route path="/system/tasks" component={SystemViewTasks} />
              <Route path="/system/deploy" component={SystemViewDeploy} />
              <Route path="/system/jobs" component={SystemViewJobs} />
              <Route path="/system/gates" component={SystemViewGates} />
              <Route
                path="/system/connectors"
                component={SystemViewConnectors}
              />
            </Switch>
          </Pane>
        </Pane>
      </div>
    </div>
  );
};

export default SystemView;
