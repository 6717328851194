export const GateOptions = [
  { label: "Open time", value: "open_time" },
  { label: "Vercel", value: "vercel" },
  // LEGACY GATES
  {
    label: "[Legacy] Activity in Timeline",
    value: "show_activity_in_timeline",
  },
  { label: "[Legacy] Connect Slack", value: "has_slack_connect" },
  { label: "[Legacy] Emails", value: "show_emails" },
  { label: "[Legacy] Materials Controls", value: "show_materials_controls" },
  { label: "[Legacy] Pin to Person", value: "pin_to_person" },
  { label: "[Legacy] Sidebar", value: "has_sidebar" },
  {
    label: "[Legacy] Activity in Agenda all events",
    value: "agenda_always_show_activity",
  },
  { label: "[Legacy] Materials", value: "use_materials" },
  { label: "[Legacy] Search in Agenda", value: "agenda_show_search" },
  { label: "[Legacy] Activity in Agenda", value: "agenda_show_activity" },
  { label: "[Legacy] Suggestions in Agenda", value: "agenda_show_suggestions" },
  {
    label: "[Legacy] Suggested in Agenda search",
    value: "agenda_show_recommended",
  },
  { label: "[Legacy] Custom rules", value: "has_rules" },
  { label: "[Legacy] Jira connect", value: "has_jira_connect" },
  { label: "[Legacy] Outlook connect", value: "has_outlook_connect" },
  { label: "[Legacy] Sales Force connect", value: "has_sales_force_connect" },
  { label: "[Legacy] Trello connect", value: "has_trello_connect" },
  { label: "[Legacy] Workstreams", value: "has_workstreams_v2" },
  {
    label: "[Legacy] Rules - Organizer Email",
    value: "has_organizer_email_rule_condition",
  },
  { label: "[Legacy] Contacts link in left nav", value: "has_contacts_link" },
  { label: "[Legacy] Links search", value: "has_links_search" },
  { label: "[Legacy] Notes search", value: "has_notes_search" },
];
