import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailNotification } from "./selectors";

export interface EmailNotificationsState {
  userNotifications: { [userId: string]: EmailNotification[] };
  userNotificationsCount: { [userId: string]: number };
}

const initialState: EmailNotificationsState = {
  userNotifications: {},
  userNotificationsCount: {},
};

export const emailNotificationsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    receiveUserNotifications: (
      state,
      action: PayloadAction<{
        userId: string;
        notifications: EmailNotification[];
      }>
    ) => {
      state.userNotifications[action.payload.userId] =
        action.payload.notifications;
    },
    receiveUserNotificationsCount: (
      state,
      action: PayloadAction<{ userId: string; count: number }>
    ) => {
      state.userNotificationsCount[action.payload.userId] =
        action.payload.count;
    },
  },
});

export const { receiveUserNotifications, receiveUserNotificationsCount } =
  emailNotificationsSlice.actions;
export default emailNotificationsSlice.reducer;
