import { Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";

import { fetchLink } from "api/links";
import { useAppDispatch, useAppSelector } from "hooks";
import { getLinkDetails } from "store/links/selectors";
import { receiveLinkDetails } from "store/links/slice";

const LinkDetail = ({ linkId }: { linkId: string }) => {
  const link = useAppSelector(getLinkDetails);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getLink = async () => {
      setLoading(true);
      try {
        const { data } = await fetchLink(linkId);
        dispatch(receiveLinkDetails(data));
      } catch (e) {
        setError("Failed to fetch link");
      } finally {
        setLoading(false);
      }
    };

    if ((!link || link.id !== linkId) && !loading && !error && linkId) {
      getLink();
    }
  }, [loading, link, error, dispatch, linkId]);

  if (!link || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(link, null, 2)}</pre>
    </div>
  );
};

export default LinkDetail;
