import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TasksState {
  taskDetails: any | null;
}

const initialState: TasksState = {
  taskDetails: null,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    receiveTaskDetails: (state, action: PayloadAction<any>) => {
      state.taskDetails = action.payload;
    },
  },
});

export const { receiveTaskDetails } = tasksSlice.actions;
export default tasksSlice.reducer;
