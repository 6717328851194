import { Badge, Button, SelectField, Spinner } from "evergreen-ui";
import { useEffect, useState } from "react";
import cx from "classnames";

import { fetchDeploymentsForApp, postDeploymentForApp } from "api/system";
import { notify } from "store/notifications/slice";
import { useAppDispatch } from "hooks";
import { NotificationType } from "store/notifications/selectors";

interface IDeployment {
  author: string;
  commit_link: string;
  date: string;
  description: string;
  diff_link: string;
  hash: string;
  is_deployed: boolean;
}

const DeploymentCandidate = ({
  deployment,
  onDeployClick,
  disabled = false,
}: {
  deployment: IDeployment;
  onDeployClick: (hash: string) => void;
  disabled: boolean;
}) => {
  return (
    <div
      className={cx("deployment-candidate--container", {
        deployed: deployment.is_deployed,
      })}
    >
      <div>
        <h4 className="deployment-candidate--title">
          {deployment.description}
        </h4>
        <div>
          {deployment.author} -{" "}
          <a target="_blank" rel="noreferrer" href={deployment.commit_link}>
            {deployment.hash}
          </a>
        </div>
        <div>
          Committed on{" "}
          {new Date(deployment.date).toLocaleString("default", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
        {!deployment.is_deployed && (
          <div>
            <a target="_blank" rel="noreferrer" href={deployment.diff_link}>
              View diff
            </a>
          </div>
        )}
      </div>
      <div>
        {!deployment.is_deployed && (
          <Button
            size="large"
            intent="success"
            onClick={() => onDeployClick(deployment.hash)}
            disabled={disabled}
          >
            Deploy
          </Button>
        )}
        {deployment.is_deployed && (
          <Badge
            color="blue"
            paddingTop={8}
            paddingBottom={8}
            paddingLeft={16}
            paddingRight={16}
            height={32}
          >
            Deployed
          </Badge>
        )}
      </div>
    </div>
  );
};

const DeploySection = ({
  deployments,
  app,
}: {
  deployments: IDeployment[];
  app: string;
}) => {
  const [isDeploying, setIsDeploying] = useState(false);
  const deployed = deployments.find((dep) => dep.is_deployed);
  const deployedHash = deployed ? deployed.hash : "none";
  const dispatch = useAppDispatch();

  const deploy = async (hash: string) => {
    if (isDeploying) {
      return;
    }

    setIsDeploying(true);
    try {
      await postDeploymentForApp(app, hash, deployedHash);
      notify({
        message: "Successfully deployed!",
        type: NotificationType.SUCCESS,
      })(dispatch);
      setTimeout(() => window.location.reload(), 1200);
    } catch (e) {
      notify({
        message: "Failed to deploy. Check console for details",
        type: NotificationType.ERROR,
      })(dispatch);
      console.warn("Failed to deploy", e);
    } finally {
      setIsDeploying(false);
    }
  };

  return (
    <div>
      {isDeploying && (
        <div className="deployment-loading--container">
          Deploying
          <Spinner marginLeft={8} size={14} />
        </div>
      )}
      {deployments.map((dep) => (
        <DeploymentCandidate
          deployment={dep}
          onDeployClick={deploy}
          disabled={isDeploying}
        />
      ))}
    </div>
  );
};

const SystemViewDeploy = () => {
  const [app, setApp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deployments, setDeployments] = useState(null);

  useEffect(() => {
    const fetchDeployments = async () => {
      if (!app) {
        return;
      }

      setLoading(true);
      try {
        const { data } = await fetchDeploymentsForApp(app);
        setDeployments(data);
      } catch (e) {
        console.warn("Failed to fetch deploy candidates", e);
      } finally {
        setLoading(false);
      }
    };

    if (app) {
      fetchDeployments();
    }
  }, [app]);

  return (
    <div>
      <SelectField
        width={240}
        value={app || undefined}
        label="App to deploy"
        onChange={(e: any) => setApp(e.target.value)}
      >
        <option value="">Choose one...</option>
        <option value="admin-web">Admin app</option>
        <option value="api">Backend</option>
        <option value="classify-web">User app</option>
        <option value="push-api">Websocket API</option>
      </SelectField>
      {loading && (
        <div className="deployment-loading--container">
          Loading
          <Spinner marginLeft={8} size={14} />
        </div>
      )}
      {deployments && app && (
        <DeploySection app={app} deployments={deployments} />
      )}
    </div>
  );
};

export default SystemViewDeploy;
