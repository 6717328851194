import { API } from "api";

export const fetchUserDocuments = (
  id: string,
  limit: number = 10,
  offset: number = 0,
  sort_column: string = "",
  sort_dir: string = "desc"
) =>
  API.get(
    `/admin/documents/?user_id=${id}&limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_dir=${sort_dir}`
  );

export const reindexDocuments = (id: string, start: string, end: string) =>
  API.post(`/admin/documents/reindex`, { user_id: id, start, end });

export const fetchDocumentDetails = (document_id: string) =>
  API.get(`/admin/documents/${document_id}`);

export const reindexDocument = (id: string) =>
  API.post(`/admin/documents/${id}/reindex`);

export const recreateDocumentActivity = (id: string) =>
  API.post(`/admin/documents/${id}/recreate-activity`);

export const fetchDocsAmountPerConnector = (user_id: string) =>
  API.get(`/admin/documents/${user_id}/count`);
