import { ArrowsVerticalIcon, Table } from "evergreen-ui";
import { useHistory } from "react-router-dom";

const LinkRow = ({ link }: any) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(link.created_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(link.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const deletedDateString = new Date(link.deleted_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/link-details/${link.id}`)}
    >
      <Table.TextCell>{link.id}</Table.TextCell>
      <Table.TextCell>{link.url}</Table.TextCell>
      <Table.TextCell>{link.type}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>
        {link.deleted_at ? deletedDateString : ""}
      </Table.TextCell>
    </Table.Row>
  );
};

const LinkTable = ({ links, setSortBy, sortByDir, setSortByDir }: any) => {
  const getNewSortState = (e: React.MouseEvent, columnName: string) => {
    setSortBy(columnName);
    setSortByDir(sortByDir === "asc" ? "desc" : "asc");
    e.stopPropagation();
  };

  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "url")}
          style={{ cursor: "pointer" }}
        >
          URL
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Type</Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "created_at")}
          style={{ cursor: "pointer" }}
        >
          Created At
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell
          onClick={(e: React.MouseEvent) => getNewSortState(e, "updated_at")}
          style={{ cursor: "pointer" }}
        >
          Updated At
          <ArrowsVerticalIcon style={{ marginLeft: 5, paddingTop: 5 }} />
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Deleted at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {links?.map((link: any) => (
          <LinkRow link={link} key={link.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default LinkTable;
