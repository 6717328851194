import { useParams } from "react-router";

import Sidebar from "components/Sidebar";
import ContactDetail from "components/ContactDetail";

const ContactDetailView = () => {
  const params = useParams<{ id: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>Contact</h1>
        <ContactDetail contactId={params.id} />
      </div>
    </div>
  );
};

export default ContactDetailView;
