import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDocument } from "./selectors";

interface ISidebarState {
  isOpen: boolean;
  documentId: string | null;
}

export type UserDocsAmountPerConn = { [connectorId: string]: string };

export interface DocumentsState {
  documentsById: { [documentId: string]: IDocument } | null;
  sidebar: ISidebarState;
  oldestTimelineFetch?: Date;
  userDocuments: IDocument[] | null;
  userDocsAmountPerConn: UserDocsAmountPerConn;
  connectorDocuments: IDocument[] | null;
  documentDetails: IDocument | null;
}

const initialState: DocumentsState = {
  documentsById: null,
  sidebar: { isOpen: false, documentId: null },
  oldestTimelineFetch: undefined,
  userDocuments: null,
  userDocsAmountPerConn: {},
  connectorDocuments: null,
  documentDetails: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    receiveDocuments: (state, action: PayloadAction<IDocument[]>) => {
      if (state.documentsById === null) {
        state.documentsById = {};
      }

      action.payload.forEach(
        (document) => (state.documentsById![document.id] = document)
      );
    },

    openSideBarToDocument: (state, action: PayloadAction<string>) => {
      state.sidebar.isOpen = true;
      state.sidebar.documentId = action.payload;
    },

    closeSidebar: (state) => {
      state.sidebar.isOpen = false;
    },

    setOldestTimelineFetch: (state, action: PayloadAction<Date>) => {
      state.oldestTimelineFetch = action.payload;
    },

    receiveUserDocuments: (state, action: PayloadAction<IDocument[]>) => {
      state.userDocuments = action.payload;
    },

    receiveConnectorDocuments: (state, action: PayloadAction<IDocument[]>) => {
      state.connectorDocuments = action.payload;
    },

    receiveDocumentDetails: (state, action: PayloadAction<IDocument>) => {
      state.documentDetails = action.payload;
    },

    receiveUserDocsAmountPerConn: (
      state,
      action: PayloadAction<{ [connectorId: string]: string }>
    ) => {
      state.userDocsAmountPerConn = action.payload;
    },
  },
});

export const {
  receiveDocuments,
  openSideBarToDocument,
  closeSidebar,
  setOldestTimelineFetch,
  receiveUserDocuments,
  receiveConnectorDocuments,
  receiveDocumentDetails,
  receiveUserDocsAmountPerConn,
} = documentsSlice.actions;
export default documentsSlice.reducer;
