import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";

import { fetchRule } from "api/rules";
import { getRuleDetails } from "store/rules/selectors";
import { receiveRuleDetails } from "store/rules/slice";
import { Spinner } from "evergreen-ui";

const RuleDetail = ({ ruleId }: { ruleId: string }) => {
  const rule = useAppSelector(getRuleDetails);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getRule = async () => {
      setLoading(true);
      try {
        const { data } = await fetchRule(ruleId);
        dispatch(receiveRuleDetails(data));
      } catch (e) {
        setError("Failed to fetch rule");
      } finally {
        setLoading(false);
      }
    };

    if ((!rule || rule.id !== ruleId) && !loading && !error && ruleId) {
      getRule();
    }
  }, [loading, rule, error, dispatch, ruleId]);

  if (!rule || loading) {
    return (
      <div>
        Loading <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <pre>{JSON.stringify(rule, null, 2)}</pre>
    </div>
  );
};

export default RuleDetail;
