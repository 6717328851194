import { Table } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { INote } from "store/notes/selectors";

const NoteRow = ({ note }: { note: INote }) => {
  const dateFmt: any = {
    month: "numeric",
    year: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const createdDateString = new Date(note.created_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const updatedDateString = new Date(note.updated_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const deletedDateString = new Date(note.deleted_at).toLocaleString(
    navigator.language,
    dateFmt
  );
  const history = useHistory();

  return (
    <Table.Row
      isSelectable
      onClick={() => history.push(`/note-details/${note.id}`)}
    >
      <Table.TextCell>{note.id}</Table.TextCell>
      <Table.TextCell>{note.data.calendarEventId as string}</Table.TextCell>
      <Table.TextCell>{createdDateString}</Table.TextCell>
      <Table.TextCell>{updatedDateString}</Table.TextCell>
      <Table.TextCell>
        {note.deleted_at ? deletedDateString : ""}
      </Table.TextCell>
    </Table.Row>
  );
};

const NoteTable = ({ notes }: { notes?: INote[] }) => {
  return (
    <Table marginBottom={20}>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Calendar event</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Deleted at</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {notes?.map((note: any) => (
          <NoteRow note={note} key={note.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default NoteTable;
