import { useEffect, useState } from "react";
import {
  SearchInput,
  Card,
  Table,
  Button,
  Pane,
  Dialog,
  Switch,
  Select,
  Tooltip,
  TextInput,
  IconButton,
  InfoSignIcon,
  SearchIcon,
  SelectMenu
} from "evergreen-ui";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import { EmailNotification } from "store/email_notifications/selectors";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DEBUG } from "constants/resources";

import { fetchUserConnectors } from "api/connectors";
import { fetchUserLinks } from "api/links";
import {
  fetchDocsAmountPerConnector,
  fetchUserDocuments,
  reindexDocuments,
} from "api/documents";
import { fetchUserRules } from "api/rules";
import { fetchUserContactLists } from "api/contact_list";
import { fetchUserContacts } from "api/contacts";
import {
  fetchUserNotifications,
  fetchUserNotificationsCount,
} from "api/notifications";
import { deleteUser, fetchUserDetails, searchEntitiesByTitleInDB, searchEntitiesByTitleInIndex, toggleSuperUser } from "api/user";
import { getUserConnectors } from "store/connectors/selectors";
import { getUserNotificationsCount } from "store/email_notifications/selectors";
import {
  getSearchedUserDocuments,
  getUserDocsAmountPerConn,
} from "store/documents/selectors";
import { getSearchedUserContacts } from "store/contacts/selectors";
import { getCurrentUser, getUserDetails } from "store/user/selector";
import { useAppSelector, usePaginationLoading } from "hooks";
import Sidebar from "components/Sidebar/index";
import ConnectorTable from "components/ConnectorTable";
import MaterialTable from "components/MaterialTable";
import DocumentTable from "components/DocumentTable";
import ContactTable from "components/ContactTable";
import UserGateDialog from "components/UserGateDialog";
import UserSettingsDialog from "components/UserSettingsDialog";
import LinkTable from "components/LinkTable";
import LoadingDots from "components/LoadingDots";
import NotificationsTable from "components/NotificationTable";
import RuleTable from "components/RuleTable";
import ContactListTable from "components/ContactListTable";
import { getUserContactLists } from "store/contact_lists/selectors";
import { receiveUserContactLists } from "store/contact_lists/slice";
import { getUserLinks } from "store/links/selectors";
import { receiveUserLinks } from "store/links/slice";
import { receiveUserDetails } from "store/user/slice";
import { receiveSearchedUserConnectors } from "store/connectors/slice";
import { receiveSearchedUserConnectorsById } from "store/connectors/slice";
import {
  receiveUserDocsAmountPerConn,
  receiveUserDocuments,
} from "store/documents/slice";
import {
  receiveUserNotifications,
  receiveUserNotificationsCount,
} from "store/email_notifications/slice";
import { receiveSearchedUserContacts } from "store/contacts/slice";
import { IActivity } from "store/activities/selectors";
import { NotificationType } from "store/notifications/selectors";
import { notify } from "store/notifications/slice";
import { getUserRules } from "store/rules/selectors";
import { receiveUserRules } from "store/rules/slice";

import "./style.css";
import { fetchUserActivities } from "api/activities";
import { receiveUserActivities } from "store/activities/slice";
import ActivityTable from "components/ActivityTable";
import { Material, getUserMaterialsCount } from "store/materials/selectors";
import { TablePaginationHeader } from "components/TableUtils/TablePagination";
import { getUserNotes } from "store/notes/selectors";
import {
  fetchUserMaterials,
  fetchUserMaterialsCount,
  backfillMaterials,
  backfillMaterialsForPins,
  backfillMaterialsForNotifications,
  backfillMaterialsFromDocs,
  backfillReprocessMaterials,
} from "api/materials";
import { fetchUserNotes } from "api/notes";
import { receiveUserNotes } from "store/notes/slice";
import NoteTable from "components/NoteTable";
import {
  receiveUserMaterials,
  receiveUserMaterialsCount,
} from "store/materials/slice";

const PAGE_SIZE = 10;

const MaterialsEntityTypeValues = [
  "ARTIFACT",
  "CONTACT",
  "DOCUMENT",
  "LINK",
  "NOTE",
];
const MaterialsActivitySource = [
  "ALL",
  "AIRTABLE",
  "CLASSIFY",
  "CONFLUENCE",
  "FIGMA",
  "GCAL",
  "GDRIVE",
  "GITHUB",
  "GMAIL",
  "JIRA",
  "MIRO",
  "NOTION",
  "SALESFORCE",
  "SLACK",
  "TRELLO",
  "UNKNOWN",
];
const MaterialsPageSizes = [10, 20, 50, 100];

const UserDetails = ({ userId }: { userId?: string }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState(userId || "");
  const [showConnectors, setShowConnectors] = useState(false);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showContactLists, setShowContactLists] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [startDateInput, setStartDate] = useState<null | moment.Moment>(null);
  const [endDateInput, setEndDate] = useState<null | moment.Moment>(null);
  const [dateFocus, setDateFocus] = useState<any>(null);
  const [isShownMaterialsDialog, setIsShownMaterialsDialog] = useState(false);
  const [materialsSource, setMaterialsSource] = useState(
    MaterialsActivitySource[0]
  );
  const [materialsPageSize, setMaterialsPageSize] = useState(
    MaterialsPageSizes[0]
  );
  // search by title in DB
  const [titleToSearch, setTitleToSearch] = useState('');
  const [searchEntitiesOptions] = useState(
    ['material', 'link', 'document'].map((label) => ({
    label,
    value: label,
  })));
  const [selectedItemsState, setSelectedItemsState] = useState<(string | number)[]>(['material', 'link', 'document']);
  const [searchEntitiesLoading, setSearchEntitiesLoading] = useState(false);
  const [searchEntitiesResult, setSearchEntitiesResult] = useState<{ [key: string]: any }>({});
  // search by title in Index
  const recordsAmountForIndexSearch = [10, 25, 50, 75, 100];
  const [selectedAmountIndexSearch, setSelectedAmountIndexSearch] = useState(recordsAmountForIndexSearch[0]);  
  const [titleToSearchIndex, setTitleToSearchIndex] = useState('');
  const [searchIndexEntitiesOptions] = useState(
    ['document autocomplete','material'].map((label) => ({
      label,
      value: label,
    })));
  const [selectedItemsIndexState, setSelectedItemsIndexState] = useState<(string | number)[]>(['document autocomplete', 'material']);
  const [searchEntitiesIndexLoading, setSearchEntitiesIndexLoading] = useState(false);
  const [searchEntitiesIndexResult, setSearchEntitiesIndexResult] = useState<{ [key: string]: any }>({});
  //activities 
  const [searchActivityInput, setSearchActivityInput] = useState("");
  const [searchActivityFilter, setSearchActivityFilter] = useState("");
  // materials for activity
  const [materialsBeforeDate, setMaterialsBeforeDate] =
    useState<null | moment.Moment>(null);
  const [materialsBeforeDateFocus, setMaterialsBeforeDateFocus] =
    useState<boolean>(false);
  const [materialsActivitiesDryRun, setMaterialsActivitiesDryRun] =
    useState(false);
  const [materialsEntityType, setMaterialsEntityType] = useState(
    MaterialsEntityTypeValues[0]
  );
  const [materialsMaxProcessed, setMaterialsMaxProcessed] = useState(200);
  // materials for pins
  const [materialsPinsDryRun, setMaterialsPinsDryRun] = useState(false);
  const [isShownMaterialsForPinsDialog, setIsShownMaterialsForPinsDialog] =
    useState(false);
  // materials for notifications
  const [
    isShownMaterialsForNotificationsDialog,
    setIsShownMaterialsForNotificationsDialog,
  ] = useState(false);
  const [materialsNotificationsDryRun, setMaterialsNotificationsDryRun] =
    useState(false);
  const [
    materialsForNotificationsUserAmount,
    setMaterialsForNotificationsUserAmount,
  ] = useState(1000);
  // general materials
  const [fullMaterialsLoading, setFullMaterialsLoading] = useState(false);
  const [advancedMaterialsShown, setAdvancedMaterialsShown] = useState(false);

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showGatesDialog, setShowGatesDialog] = useState(false);
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] =
    useState(false);
  const [documentsSortedBy, setDocumentsSortedBy] = useState("");
  const [documentsSortedByDir, setDocumentsSortedByDir] = useState("desc");
  const [materialsSortedBy, setMaterialsSortedBy] = useState("");
  const [linksSortedBy, setLinksSortedBy] = useState("");
  const [linksSortedByDir, setLinksSortedByDir] = useState("desc");
  const [error, setError] = useState<string>();

  const userDetails = useAppSelector(getUserDetails);
  const userConnectors = useAppSelector(getUserConnectors);
  const userDocuments = useAppSelector(getSearchedUserDocuments);
  const userContacts = useAppSelector(getSearchedUserContacts);
  const userRules = useAppSelector(getUserRules);
  const userLinks = useAppSelector(getUserLinks);
  const userDocsAmountPerConn = useAppSelector(getUserDocsAmountPerConn);
  const userNotificationsCount = useAppSelector((s) =>
    getUserNotificationsCount(s, userId || "")
  );
  const userNotes = useAppSelector((s) => getUserNotes(s, userId || ""));
  const userContactLists = useAppSelector(getUserContactLists);
  const currentUser = useAppSelector(getCurrentUser);

  const dispatch = useDispatch();
  const userMaterialsCount = useAppSelector((s) =>
    getUserMaterialsCount(s, userId || "")
  );
  const history = useHistory();

  const showError = (message: string) => {
    notify({
      message: `Failed to ${message}. Please try again`,
      type: NotificationType.ERROR,
    })(dispatch);
  };

  const handleSearchSubmit = async (e: any) => {
    e?.preventDefault();
    if (error) {
      return;
    }

    setIsSearching(true);
    try {
      const { data } = await fetchUserDetails(searchValue);
      dispatch(receiveUserDetails(data));

      await Promise.allSettled([
        fetchUserConnectors(data.id),
        fetchUserContacts(data.id),
        fetchUserRules(data.id),
        fetchUserContactLists(data.id),
        fetchUserNotes(data.id),
        fetchUserMaterialsCount(data.id),
        fetchDocsAmountPerConnector(data.id),
        fetchUserNotificationsCount(data.id),
      ]).then(
        ([
          connectorsRes,
          contactsRes,
          rulesRes,
          contactListRes,
          notesRes,
          materialsCountRes,
          docsAmountRes,
          userNotificationsCountRes,
        ]) => {
          if (connectorsRes.status === "fulfilled") {
            dispatch(receiveSearchedUserConnectors(connectorsRes.value.data));
            dispatch(
              receiveSearchedUserConnectorsById(connectorsRes.value.data)
            );
          } else {
            showError("fetch connectors");
          }

          if (contactsRes.status === "fulfilled") {
            dispatch(receiveSearchedUserContacts(contactsRes.value.data));
          } else {
            showError("fetch contacts");
          }

          if (rulesRes.status === "fulfilled") {
            dispatch(receiveUserRules(rulesRes.value.data));
          } else {
            showError("fetch rules");
          }

          if (contactListRes.status === "fulfilled") {
            dispatch(receiveUserContactLists(contactListRes.value.data));
          } else {
            showError("fetch contact lists");
          }

          if (notesRes.status === "fulfilled") {
            dispatch(
              receiveUserNotes({
                userId: data.id,
                notes: notesRes.value.data,
              })
            );
          } else {
            showError("fetch notes");
          }

          if (materialsCountRes.status === "fulfilled") {
            dispatch(
              receiveUserMaterialsCount({
                userId: data.id,
                count: materialsCountRes.value.data,
              })
            );
          } else {
            showError("fetch materials count");
          }

          if (docsAmountRes.status === "fulfilled") {
            dispatch(receiveUserDocsAmountPerConn(docsAmountRes.value.data));
          } else {
            showError("fetch documents count");
          }

          if (userNotificationsCountRes.status === "fulfilled") {
            dispatch(
              receiveUserNotificationsCount({
                userId: data.id,
                count: userNotificationsCountRes.value.data,
              })
            );
          } else {
            showError("fetch user notifications");
          }
        }
      );
    } catch (err) {
      setError("Failed to search. Please try again");
      showError("search");
    } finally {
      setTimeout(() => setIsSearching(false), 100);
    }
  };

  const {
    currentResult: userMaterials,
    isFirstPage: isMaterialsFirstPage,
    isLastPage: isMaterialsLastPage,
    onNextPage: onMaterialsNext,
    onPreviousPage: onMaterialsPrevious,
    paginatingDataLoading: materialsPaginatingDataLoading,
    page: materialsPage,
  } = usePaginationLoading<Material>(
    (limit, offset) => {
      if (userDetails?.id) {
        return fetchUserMaterials(
          userDetails.id,
          limit,
          offset,
          materialsSource === "ALL" ? "" : materialsSource,
          materialsSortedBy
        );
      }
      return Promise.reject("No ID");
    },
    (data) => receiveUserMaterials({ userId: userDetails.id, materials: data }),
    (offset: number) =>
      `${offset}${userDetails?.id}${materialsSortedBy}${materialsSource}${materialsPageSize}`,
    () => showError("fetch materials"),
    true,
    materialsPageSize
  );

  const {
    currentResult: userActivities,
    isFirstPage: isActivitiesFirstPage,
    isLastPage: isActivitiesLastPage,
    onNextPage: onActivitiesNext,
    onPreviousPage: onActivitiesPrevious,
    paginatingDataLoading: activitiesPaginatingDataLoading,
    page: activitiesPage,
  } = usePaginationLoading<IActivity>(
    (limit, offset) => {
      if (userDetails?.id) {
        return fetchUserActivities(
          userDetails.id,
          limit,
          offset,
          searchActivityFilter
        );
      }
      return Promise.reject("No ID");
    },
    (data) => receiveUserActivities(data),
    (offset: number) =>
      `${offset}${userDetails?.id}${searchActivityFilter}`,
    () => showError("fetch activities"),
    true
  );

  const {
    currentResult: userNotifications,
    isFirstPage: isNotificationsFirstPage,
    isLastPage: isNotificationsLastPage,
    onNextPage: onNotificationsNext,
    onPreviousPage: onNotificationsPrevious,
    paginatingDataLoading: notificationsPaginatingDataLoading,
    page: notificationsPage,
  } = usePaginationLoading<EmailNotification>(
    (limit, offset) => {
      if (userDetails?.id) {
        return fetchUserNotifications(userDetails.id, limit, offset);
      }
      return Promise.reject("No ID");
    },
    (data) =>
      receiveUserNotifications({ userId: userDetails.id, notifications: data }),
    (offset: number) => `${offset}${userDetails?.id}`,
    () => showError("fetch notifications"),
    true
  );

  const {
    isFirstPage: isDocumentsFirstPage,
    isLastPage: isDocumentsLastPage,
    onNextPage: onDocumentsNext,
    onPreviousPage: onDocumentsPrevious,
    paginatingDataLoading: documentsPaginatingDataLoading,
    page: documentsPage,
  } = usePaginationLoading(
    (limit, offset) => {
      if (userDetails?.id) {
        return fetchUserDocuments(
          userDetails.id,
          limit,
          offset,
          documentsSortedBy,
          documentsSortedByDir
        );
      }
      return Promise.reject("No ID");
    },
    receiveUserDocuments,
    (offset: number) =>
      `${offset}${userDetails?.id}${documentsSortedBy}${documentsSortedByDir}`,
    () => showError("fetch documents")
  );

  const {
    isFirstPage: isLinksFirstPage,
    isLastPage: isLinksLastPage,
    onNextPage: onLinksNext,
    onPreviousPage: onLinksPrevious,
    paginatingDataLoading: linksPaginatingDataLoading,
    page: linksPage,
  } = usePaginationLoading(
    (limit, offset) => {
      if (userDetails?.id) {
        return fetchUserLinks(userDetails.id, limit, offset, linksSortedBy);
      }
      return Promise.reject("No ID");
    },
    receiveUserLinks,
    (offset: number) =>
      `${offset}${userDetails?.id}${linksSortedBy}${linksSortedByDir}`,
    () => showError("fetch links")
  );

  useEffect(() => {
    if (
      userId &&
      (!userDetails ||
        (userDetails.id !== userId && userId !== userDetails.email)) &&
      !isSearching
    ) {
      if (userDetails && userDetails.id !== userId) {
        history.push(`/user-details/${userDetails.id}`);
      }
      handleSearchSubmit(undefined);
    }
  });

  useEffect(() => {
    setError("");
  }, [searchValue]);

  const toggleShowConnectors = () => setShowConnectors(!showConnectors);

  const toggleShowMaterials = () => setShowMaterials(!showMaterials);

  const toggleShowDocuments = () => setShowDocuments(!showDocuments);

  const toggleShowContacts = () => setShowContacts(!showContacts);

  const toggleShowRules = () => setShowRules(!showRules);

  const toggleShowContactLists = () => setShowContactLists(!showContactLists);

  const toggleShowLinks = () => setShowLinks(!showLinks);

  const toggleShowActivities = () => setShowActivities(!showActivities);

  const toggleShowNotes = () => setShowNotes(!showNotes);

  const toggleShowNotifications = () =>
    setShowNotifications(!showNotifications);

  const toggleAdminUser = async () => {
    const { id: searchedUserId } = userDetails;
    const { id: currentUserId } = currentUser;

    if (searchedUserId === currentUserId) {
      notify({
        message: "Whoa! You almost locked yourself out",
        type: NotificationType.WARNING,
      })(dispatch);
      return;
    }

    try {
      setLoading(true);
      const { data } = await toggleSuperUser(searchedUserId);
      dispatch(receiveUserDetails(data));
      notify({
        message: "Successfully updated user",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      showError("toggle admin");
    } finally {
      setTimeout(() => setLoading(false), 100);
    }
  };

  const deleteSearchedUser = async () => {
    if (!DEBUG) {
      return;
    }

    const { id: searchedUserId } = userDetails;
    const { id: currentUserId } = currentUser;

    if (searchedUserId === currentUserId) {
      notify({
        message: "Whoa! You almost deleted your own account",
        type: NotificationType.WARNING,
      })(dispatch);
      return;
    }

    try {
      await deleteUser(searchedUserId);
      notify({
        message: "Successfully deleted user",
        type: NotificationType.SUCCESS,
      })(dispatch);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      showError("delete user");
    }
  };

  const submitBackfillMaterialsFromActivities = async (close: any) => {
    const { id: searchedUserId } = userDetails;

    if (!materialsBeforeDate) {
      notify({
        message: "Please choose materials before date",
        type: NotificationType.WARNING,
      })(dispatch);
      return;
    }

    try {
      const beforeDate = materialsBeforeDate.endOf("day").toISOString();
      await backfillMaterials(
        searchedUserId,
        materialsActivitiesDryRun,
        materialsEntityType,
        beforeDate,
        materialsMaxProcessed
      );
      setTimeout(() => close(), 100);
      notify({
        message: "Successfully started materials backfill from activities",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  };

  const submitBackfillMaterialsFromPins = async (close: any) => {
    const { id: searchedUserId } = userDetails;

    try {
      await backfillMaterialsForPins(searchedUserId, materialsPinsDryRun);
      setTimeout(() => close(), 100);
      notify({
        message: "Successfully started materials backfill from pins",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  };

  const submitBackfillMaterialsFromNotifications = async (close: any) => {
    const { id: searchedUserId } = userDetails;
    try {
      await backfillMaterialsForNotifications(
        searchedUserId,
        materialsForNotificationsUserAmount,
        materialsNotificationsDryRun
      );
      setTimeout(() => close(), 100);
      notify({
        message: "Successfully started materials backfill from notifications",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  };


  const runBackfillReprocessMaterials = async () => {
    const { id: searchedUserId } = userDetails;
    try {
      await backfillReprocessMaterials(searchedUserId);
      notify({
        message: "Successfully started materials backfill",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  }

  const runFullBackfillMaterials = async () => {
    const { id: searchedUserId } = userDetails;
    const defaultRecordsAmount = 1000;
    try {
      setFullMaterialsLoading(true);
      await backfillMaterialsForNotifications(
        searchedUserId,
        defaultRecordsAmount,
        false
      );
      await backfillMaterialsForPins(searchedUserId, false);
      await backfillMaterials(
        searchedUserId,
        false,
        "LINK",
        new Date().toISOString(),
        defaultRecordsAmount
      );
      await backfillMaterialsFromDocs(searchedUserId);
      setFullMaterialsLoading(false);

      notify({
        message: "Successfully started full materials backfill",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      setFullMaterialsLoading(false);
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  };

  const submitReindexDocs = async (close: any) => {
    const { id: searchedUserId } = userDetails;

    if (!startDateInput || !endDateInput) {
      notify({
        message: "Please choose a start and end date",
        type: NotificationType.WARNING,
      })(dispatch);
      return;
    }

    try {
      const startOfDay = startDateInput.startOf("day").toISOString();
      const endOfDay = endDateInput.endOf("day").toISOString();
      await reindexDocuments(searchedUserId, startOfDay, endOfDay);
      setTimeout(() => close(), 100);
      notify({
        message: "Successfully reindexed documents",
        type: NotificationType.SUCCESS,
      })(dispatch);
    } catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    }
  };

  const onDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const searchRecordsByTitleInDB = async (userId: string, title: string, entityTypes: string[]) => {
    setSearchEntitiesLoading(true);
    try{
      const resp = await searchEntitiesByTitleInDB(userId, title, entityTypes);
      if (Object.keys(resp.data).every(key => resp.data[key].length === 0)){
        notify({
          message: `No entities found for "${title}" search request`,
          type: NotificationType.INFO,
        })(dispatch);
      }
      
      setSearchEntitiesResult(resp.data);
    }
    catch (error){
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setTimeout(() => setSearchEntitiesLoading(false), 100);
    }
  }

  const searchRecordsByTitleIndex = async (userId: string, title: string, entityTypes: string[], limit: number) => {
    setSearchEntitiesIndexLoading(true);
    try {
      const respData = await searchEntitiesByTitleInIndex(userId, title, entityTypes, limit);      
      if (Object.keys(respData).every(key => respData[key].length === 0)) {
        notify({
          message: `No entities found for "${title}" Index search request`,
          type: NotificationType.INFO,
        })(dispatch);
      }

      setSearchEntitiesIndexResult(respData);
    }
    catch (error) {
      notify({
        message: "Something went wrong, try again",
        type: NotificationType.ERROR,
      })(dispatch);
    } finally {
      setTimeout(() => setSearchEntitiesIndexLoading(false), 100);
    }
  }

  return (
    <div>
      <div>
        <Pane>
          <Dialog
            isShown={isShown}
            title="Choose a date range"
            onCloseComplete={() => setIsShown(false)}
            confirmLabel="Submit"
            onConfirm={(close) => submitReindexDocs(close)}
            isConfirmDisabled={!startDateInput || !endDateInput}
          >
            <DateRangePicker
              startDate={startDateInput}
              startDateId={
                startDateInput ? startDateInput.toISOString() : "no_start"
              }
              endDate={endDateInput}
              endDateId={endDateInput ? endDateInput.toISOString() : "no_end"}
              onDatesChange={onDatesChange}
              focusedInput={dateFocus}
              onFocusChange={setDateFocus}
              withPortal
              isOutsideRange={(m) => m > moment()}
            />
          </Dialog>
          <UserGateDialog
            current={userDetails?.properties}
            visible={showGatesDialog}
            onClose={() => setShowGatesDialog(false)}
            userId={userId || userDetails?.id || "none"}
          />
        </Pane>
        <Pane>
          <Dialog
            isShown={isShownMaterialsDialog}
            title="Choose materials backfill"
            onCloseComplete={() => setIsShownMaterialsDialog(false)}
            confirmLabel="Submit"
            onConfirm={(close) => submitBackfillMaterialsFromActivities(close)}
            isConfirmDisabled={!materialsBeforeDate}
          >
            <label className="user-gate-dialog--label">Before Date</label>
            <SingleDatePicker
              date={materialsBeforeDate}
              onDateChange={setMaterialsBeforeDate}
              focused={materialsBeforeDateFocus}
              onFocusChange={({ focused }) =>
                setMaterialsBeforeDateFocus(focused)
              }
              withPortal
              id={
                materialsBeforeDate
                  ? materialsBeforeDate.toISOString()
                  : "no_id"
              }
              isOutsideRange={(m) => m > moment()}
            />
            <br />
            <label className="user-gate-dialog--label">Dry Run</label>
            <Switch
              className="user-gate-dialog--switch"
              height={24}
              checked={materialsActivitiesDryRun}
              onChange={() =>
                setMaterialsActivitiesDryRun(!materialsActivitiesDryRun)
              }
            />
            <br />
            <label className="user-gate-dialog--label">Entity Type</label>
            <br />
            <Select
              value={materialsEntityType}
              onChange={(event) => setMaterialsEntityType(event.target.value)}
            >
              {MaterialsEntityTypeValues.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
            <br />
            <label className="user-gate-dialog--label">Max Processed</label>
            <br />
            <TextInput
              onChange={(e: any) => setMaterialsMaxProcessed(e.target.value)}
              value={materialsMaxProcessed}
            />
          </Dialog>
          <UserGateDialog
            current={userDetails?.properties}
            visible={showGatesDialog}
            onClose={() => setShowGatesDialog(false)}
            userId={userId || userDetails?.id || "none"}
          />
        </Pane>
        <Pane>
          <Dialog
            isShown={isShownMaterialsForNotificationsDialog}
            title="Choose materials backfill for notifications"
            onCloseComplete={() =>
              setIsShownMaterialsForNotificationsDialog(false)
            }
            confirmLabel="Submit"
            onConfirm={(close) =>
              submitBackfillMaterialsFromNotifications(close)
            }
          >
            <label className="user-gate-dialog--label">Amount</label>
            <br />
            <TextInput
              onChange={(e: any) =>
                setMaterialsForNotificationsUserAmount(e.target.value)
              }
              value={materialsForNotificationsUserAmount}
            />
            <br />
            <label className="user-gate-dialog--label">Dry Run</label>
            <Switch
              className="user-gate-dialog--switch"
              height={24}
              checked={materialsNotificationsDryRun}
              onChange={() =>
                setMaterialsNotificationsDryRun(!materialsNotificationsDryRun)
              }
            />
          </Dialog>
          <UserGateDialog
            current={userDetails?.properties}
            visible={showGatesDialog}
            onClose={() => setShowGatesDialog(false)}
            userId={userId || userDetails?.id || "none"}
          />
        </Pane>
        <Pane>
          <Dialog
            isShown={isShownMaterialsForPinsDialog}
            title="Choose materials backfill for pins"
            onCloseComplete={() => setIsShownMaterialsForPinsDialog(false)}
            confirmLabel="Submit"
            onConfirm={(close) => submitBackfillMaterialsFromPins(close)}
          >
            <label className="user-gate-dialog--label">Dry Run</label>
            <Switch
              className="user-gate-dialog--switch"
              height={24}
              checked={materialsPinsDryRun}
              onChange={() => setMaterialsPinsDryRun(!materialsPinsDryRun)}
            />
          </Dialog>
          <UserGateDialog
            current={userDetails?.properties}
            visible={showGatesDialog}
            onClose={() => setShowGatesDialog(false)}
            userId={userId || userDetails?.id || "none"}
          />
        </Pane>
        <Pane>
          <UserSettingsDialog
            current={userDetails?.properties}
            visible={showSettingsDialog}
            onClose={() => setShowSettingsDialog(false)}
            userId={userId || userDetails?.id || "none"}
          />
        </Pane>
        <form onSubmit={handleSearchSubmit}>
          <SearchInput
            placeholder="Search user by email or id..."
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.currentTarget.value)}
          />
        </form>
        <div>
          {isSearching && (
            <div className="user-details--loading-data">
              <span>Searching</span>
              <LoadingDots />
            </div>
          )}
          {userDetails && (
            <div>
              <Button
                appearance="primary"
                className="admin-btn"
                onClick={toggleAdminUser}
                marginTop={8}
                isLoading={loading}
              >
                {userDetails.is_superuser ? "Disable Admin" : "Enable Admin"}
              </Button>
              <Button
                appearance="primary"
                marginLeft={8}
                marginTop={8}
                onClick={() => setIsShown(true)}
              >
                Reindex Documents
              </Button>
              <Button
                appearance="primary"
                marginLeft={8}
                marginTop={8}
                onClick={() => setShowGatesDialog(true)}
              >
                Change gates
              </Button>
              <Button
                appearance="primary"
                marginLeft={8}
                marginTop={8}
                onClick={() => setShowSettingsDialog(true)}
              >
                Change settings
              </Button>

              {DEBUG ? (
                <Pane display="contents">
                  <Dialog
                    isShown={showDeleteAccountConfirmation}
                    title="Are you sure you want to delete this account?"
                    intent="danger"
                    onCloseComplete={() =>
                      setShowDeleteAccountConfirmation(false)
                    }
                    onConfirm={deleteSearchedUser}
                    confirmLabel="Delete"
                  >
                    Please make sure you want to delete this account and all the
                    associated information!
                  </Dialog>
                  <Button
                    appearance="primary"
                    intent="danger"
                    marginLeft={8}
                    marginTop={8}
                    disabled={userDetails.is_superuser}
                    onClick={() => setShowDeleteAccountConfirmation(true)}
                  >
                    Delete account
                  </Button>
                  <Tooltip
                    content={
                      userDetails.is_superuser
                        ? "Admin accounts can't be deleted"
                        : "Delete this user account"
                    }
                  >
                    <IconButton marginTop={8} icon={InfoSignIcon} />
                  </Tooltip>
                </Pane>
              ) : null}
              <h3 style={{ marginBottom: 0 }}>Materials</h3>
              <div>
                <Tooltip content="Backfill from activity links, notifications [up to 1000 records each]. Backfill from pins, activity docs [all]">
                  <Button
                    appearance="primary"
                    marginLeft={8}
                    marginTop={8}
                    onClick={() => {
                      runFullBackfillMaterials();
                    }}
                    isLoading={fullMaterialsLoading}
                  >
                    Full backfill Materials
                  </Button>
                </Tooltip>
                {advancedMaterialsShown ? (
                  <span>
                    <Button
                      appearance="primary"
                      marginLeft={8}
                      marginTop={8}
                      onClick={() => setIsShownMaterialsDialog(true)}
                    >
                      Backfill Materials from Activity
                    </Button>
                    <Button
                      appearance="primary"
                      marginLeft={8}
                      marginTop={8}
                      onClick={() => setIsShownMaterialsForPinsDialog(true)}
                    >
                      Backfill Materials from Pins
                    </Button>
                    <Button
                      appearance="primary"
                      marginLeft={8}
                      marginTop={8}
                      onClick={() =>
                        setIsShownMaterialsForNotificationsDialog(true)
                      }
                    >
                      Backfill Materials from Notifications
                    </Button>
                    <Button
                      appearance="primary"
                      marginLeft={8}
                      marginTop={8}
                      onClick={() => runBackfillReprocessMaterials()}
                    >
                      Reprocess Existing Materials
                    </Button>
                  </span>
                ) : (
                  <Button
                    appearance="minimal"
                    marginLeft={8}
                    marginTop={8}
                    onClick={() => {
                      setAdvancedMaterialsShown(true);
                    }}
                  >
                    More options
                  </Button>
                )}
              </div>
              <h3 style={{ marginBottom: 0 }}>Search records in DB</h3>
              <br />
              <TextInput
                onChange={(e: any) =>
                  setTitleToSearch(e.target.value)
                }
                value={titleToSearch}
                placeholder="Search title"
              />
              <SelectMenu
                isMultiSelect
                hasTitle={false}
                options={searchEntitiesOptions}
                selected={selectedItemsState as string[]}
                hasFilter={false}
                height={100}
                onSelect={(item) => {
                  const selected = [...selectedItemsState, item.value]
                  const selectedItems = selected                
                  setSelectedItemsState(selectedItems)
                }}
                onDeselect={(item) => {
                  const deselectedItemIndex = selectedItemsState.indexOf(item.value)
                  const selectedItems = selectedItemsState.filter((_item, i) => i !== deselectedItemIndex)
                  setSelectedItemsState(selectedItems)
                }}
              >
                <Tooltip content={selectedItemsState.join(', ')}>
                  <Button>{'Search entities...'}</Button>
                </Tooltip>
              </SelectMenu>
              <Button
                appearance="primary"
                marginLeft={8}
                onClick={() => {
                  searchRecordsByTitleInDB(userDetails.id, titleToSearch, selectedItemsState as string[])
                }}
                isLoading={searchEntitiesLoading}
              >
                Search
              </Button>
              {Boolean(Object.keys(searchEntitiesResult).length) && <h4 style={{ marginBottom: 0 }}>Search results</h4>}
              {Object.keys(searchEntitiesResult).map((entityKey) => (searchEntitiesResult[entityKey].length) ?
                <Pane flex="1" overflowY="scroll" padding={16}>
                  <h4>{`${entityKey}: ${searchEntitiesResult[entityKey].length} items`}</h4>
                  <Card
                    overflow="scroll"
                    padding={20}
                    border="muted"
                    elevation={0}
                    height={250}
                    alignItems="center"
                    justifyContent="center"
                    background="tint1"
                  >
                    <pre>{JSON.stringify(searchEntitiesResult[entityKey], null, 2)}</pre>
                  </Card>
                </Pane>
                : null)}
              <br />
              <h3 style={{ marginBottom: 0 }}>Search records in Index</h3>
              <br />
              <TextInput
                onChange={(e: any) =>
                  setTitleToSearchIndex(e.target.value)
                }
                value={titleToSearchIndex}
                placeholder="Search title"
              />
              <SelectMenu
                isMultiSelect
                hasTitle={false}
                options={searchIndexEntitiesOptions}
                selected={selectedItemsIndexState as string[]}
                hasFilter={false}
                height={65}
                onSelect={(item) => {
                  const selected = [...selectedItemsIndexState, item.value]
                  setSelectedItemsIndexState(selected)
                }}
                onDeselect={(item) => {
                  const deselectedItemIndex = selectedItemsIndexState.indexOf(item.value)
                  const selectedItems = selectedItemsIndexState.filter((_item, i) => i !== deselectedItemIndex)
                  setSelectedItemsIndexState(selectedItems)
                }}
              >
                <Tooltip content={selectedItemsIndexState.join(', ')}>
                  <Button>Search entities...</Button>
                </Tooltip>
              </SelectMenu>
              <Tooltip content={'Records limit'}>
                <Select
                  value={selectedAmountIndexSearch}
                  onChange={(event) => setSelectedAmountIndexSearch(Number(event.target.value))}
                >
                  {recordsAmountForIndexSearch.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </Tooltip>
              <Button
                appearance="primary"
                marginLeft={8}
                onClick={() => {
                  searchRecordsByTitleIndex(userDetails.id, titleToSearchIndex, selectedItemsIndexState as string[], selectedAmountIndexSearch)
                }}
                isLoading={searchEntitiesIndexLoading}
              >
                Search
              </Button>
              {Boolean(Object.keys(searchEntitiesIndexResult).length) && <h4 style={{ marginBottom: 0 }}>Search results</h4>}
              {Object.keys(searchEntitiesIndexResult).map((entityKey) => (searchEntitiesIndexResult[entityKey].length) ? 
                <Pane flex="1" overflowY="scroll" padding={16}>
                  <h4>{`${entityKey}: ${searchEntitiesIndexResult[entityKey].length} items`}</h4>
                  <Card
                    overflow="scroll"
                    padding={20}
                    border="muted"
                    elevation={0}
                    height={250}
                    alignItems="center"
                    justifyContent="center"
                    background="tint1"
                  >
                    <pre>{JSON.stringify(searchEntitiesIndexResult[entityKey], null, 2)}</pre>
                  </Card>
                </Pane>
              : null)}
              
              <Table width={1000} marginY={24}>
                <Table.Head>
                  <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Org</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Superuser</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <Table.TextCell>{userDetails.full_name}</Table.TextCell>
                    <Table.TextCell>{userDetails.email}</Table.TextCell>
                    <Table.TextCell>{userDetails.status}</Table.TextCell>
                    <Table.TextCell>
                      {
                        (
                          userDetails.org.properties.find(
                            (prop) => prop.name === "name"
                          ) || { value: <em>(no company)</em> }
                        ).value
                      }
                    </Table.TextCell>
                    <Table.TextCell>
                      {userDetails.is_superuser === false ? "False" : "True"}
                    </Table.TextCell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  cursor="pointer"
                  onClick={toggleShowConnectors}
                >
                  <h3>
                    Connectors (
                    {userConnectors?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userConnectors?.length}
                    )
                  </h3>
                  {showConnectors && (
                    <ConnectorTable
                      connectors={userConnectors}
                      docsAmountPerConnector={userDocsAmountPerConn}
                    />
                  )}
                </Card>
              </div>

              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowMaterials}
                  cursor="pointer"
                >
                  <h3>
                    {materialsSource} Materials (
                    {userMaterials?.length === materialsPageSize
                      ? `${materialsPageSize}+`
                      : userMaterials?.length}
                    ), page {materialsPage}
                  </h3>
                  {showMaterials && (
                    <>
                      <h4>Activity Source:</h4>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {MaterialsActivitySource.map((activitySource) => (
                          <Button
                            appearance={
                              activitySource === materialsSource
                                ? "primary"
                                : undefined
                            }
                            key={activitySource}
                            onClick={(e: React.MouseEvent) => {
                              setMaterialsSource(activitySource);
                              e.stopPropagation();
                            }}
                          >
                            {activitySource}
                          </Button>
                        ))}
                      </div>
                      <br />
                      <TablePaginationHeader
                        loading={materialsPaginatingDataLoading}
                        isFirstPage={isMaterialsFirstPage}
                        onPreviousPage={onMaterialsPrevious}
                        isLastPage={isMaterialsLastPage}
                        onNextPage={onMaterialsNext}
                        totalRecords={userMaterialsCount}
                        currentPageSize={materialsPageSize}
                        pageSizes={MaterialsPageSizes}
                        setPageSize={setMaterialsPageSize}
                      />
                      <MaterialTable
                        materials={userMaterials || []}
                        setSortBy={setMaterialsSortedBy}
                      />
                    </>
                  )}
                </Card>
              </div>

              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowDocuments}
                  cursor="pointer"
                >
                  <h3>
                    Documents (
                    {userDocuments?.length === 10
                      ? `${PAGE_SIZE}+`
                      : userDocuments?.length}
                    ), page {documentsPage}
                  </h3>
                  {showDocuments && (
                    <>
                      <TablePaginationHeader
                        loading={documentsPaginatingDataLoading}
                        isFirstPage={isDocumentsFirstPage}
                        onPreviousPage={onDocumentsPrevious}
                        isLastPage={isDocumentsLastPage}
                        onNextPage={onDocumentsNext}
                      />
                      <DocumentTable
                        documents={userDocuments}
                        setSortBy={setDocumentsSortedBy}
                        sortByDir={documentsSortedByDir}
                        setSortByDir={setDocumentsSortedByDir}
                      />
                    </>
                  )}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowContactLists}
                  cursor="pointer"
                >
                  <h3>
                    Contact Lists (
                    {userContactLists?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userContactLists?.length}
                    )
                  </h3>
                  {showContactLists && (
                    <ContactListTable contactLists={userContactLists} />
                  )}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowRules}
                  cursor="pointer"
                >
                  <h3>
                    Rules (
                    {userRules?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userRules?.length}
                    )
                  </h3>
                  {showRules && <RuleTable rules={userRules} />}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowContacts}
                  cursor="pointer"
                >
                  <h3>
                    Contacts (
                    {userContacts?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userContacts?.length}
                    )
                  </h3>
                  {showContacts && <ContactTable contacts={userContacts} />}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowLinks}
                  cursor="pointer"
                >
                  <h3>
                    Links (
                    {userLinks?.length === 10 ? "10+" : userLinks?.length}),
                    page {linksPage}
                  </h3>
                  {showLinks && (
                    <>
                      <TablePaginationHeader
                        loading={linksPaginatingDataLoading}
                        isFirstPage={isLinksFirstPage}
                        onPreviousPage={onLinksPrevious}
                        isLastPage={isLinksLastPage}
                        onNextPage={onLinksNext}
                      />
                      <LinkTable
                        links={userLinks}
                        setSortBy={setLinksSortedBy}
                        sortByDir={linksSortedByDir}
                        setSortByDir={setLinksSortedByDir}
                      />
                    </>
                  )}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowActivities}
                  cursor="pointer"
                >
                  <h3>
                    Activities (
                    {userActivities?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userActivities?.length}
                    ), page {activitiesPage}
                  </h3>
                  {showActivities && (
                    <>
                      < TablePaginationHeader 
                        loading={activitiesPaginatingDataLoading}
                        isFirstPage={isActivitiesFirstPage}
                        onPreviousPage={onActivitiesPrevious}
                        isLastPage={isActivitiesLastPage}
                        onNextPage={onActivitiesNext}
                      />
                      <br />
                      <TextInput
                        placeholder="Search by handler, name, handler_id"
                        value={searchActivityInput}
                        onChange={(e: any) => setSearchActivityInput(e.currentTarget.value)}
                        onClick={(e: any) => e.stopPropagation()}
                      />
                      <Button
                        iconBefore={SearchIcon}
                        marginLeft={8}
                        onClick={(e: any) => { e.stopPropagation(); setSearchActivityFilter(searchActivityInput)}}
                      >
                        Search
                      </Button>
                        
                      <ActivityTable activities={userActivities}/>
                    </>
                  )}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowNotes}
                  cursor="pointer"
                >
                  <h3>
                    Notes (
                    {userNotes?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userNotes?.length}
                    )
                  </h3>
                  {showNotes && <NoteTable notes={userNotes} />}
                </Card>
              </div>
              <div>
                <Card
                  border="muted"
                  marginY={24}
                  width={1000}
                  paddingX={10}
                  elevation={1}
                  onClick={toggleShowNotifications}
                  cursor="pointer"
                >
                  <h3>
                    Notifications (
                    {userNotifications?.length === PAGE_SIZE
                      ? `${PAGE_SIZE}+`
                      : userNotifications?.length}
                    ), page {notificationsPage}
                  </h3>
                  {showNotifications && (
                    <>
                      <TablePaginationHeader
                        loading={notificationsPaginatingDataLoading}
                        isFirstPage={isNotificationsFirstPage}
                        onPreviousPage={onNotificationsPrevious}
                        isLastPage={isNotificationsLastPage}
                        onNextPage={onNotificationsNext}
                        totalRecords={userNotificationsCount}
                      />
                      <NotificationsTable
                        notifications={userNotifications || []}
                      />
                    </>
                  )}
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const UserDetailsContainer = () => {
  const params = useParams<{ id?: string }>();

  return (
    <div className="sidebar-sibling-content--container">
      <Sidebar />
      <div className="sidebar-padding--adjustment">
        <h1>User Details</h1>
        <UserDetails userId={params.id} />
      </div>
    </div>
  );
};

export default UserDetailsContainer;
