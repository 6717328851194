import { RootState } from "store";

export interface IConnector {
  id: string;
  status: string;
  provider: string;
  meta: any;
  created_at: string;
  updated_at: string;
  external_account_id: string;
  params: any;
  user_id: string;
}

const root = (state: RootState) => state.connectors;
export const getConnectorsList = (state: RootState) =>
  Object.values(root(state).connectorsById);
export const getHasNoConnectors = (state: RootState) =>
  getConnectorsList(state).length === 0;
export const getConnector = (state: RootState, id: string) =>
  root(state).connectorsById[id];
export const getUserConnectors = (state: RootState) =>
  root(state).userConnectors;
export const getUserConnectorsById = (state: RootState, id: string) =>
  root(state).searchedUserConnectorsById[id];
export const getConnectorDetails = (state: RootState) =>
  root(state).connectorDetails;
