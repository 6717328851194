import { API } from "api";

export const fetchMe = () => API.get("/api/v1/users/me");

export const fetchRecentSignups = (limit: number = 10, offset: number = 0) =>
  API.get(`/admin/users/?limit=${limit}&offset=${offset}`);

export const fetchUserDetails = (email_or_id: string) =>
  API.get(`/admin/users/${email_or_id}`);

export const toggleSuperUser = (email_or_id: string) =>
  API.post(`/admin/users/${email_or_id}/toggle-superuser`);

export const deleteUser = (email_or_id: string) =>
  API.delete(`/admin/users/${email_or_id}`);

export const patchGate = (userId: string, name: string, allowed: boolean) =>
  API.patch(`/admin/users/${userId}/gates`, { name, allowed });

export const putSetting = (userId: string, name: string, value: string) =>
  API.put(`/admin/users/${userId}/settings/${name}`, {
    value,
    prop_type: "STRING",
  });

export const resetPasswordViaEmail = (email: string) =>
  API.post("/api/v1/users/reset-pw", { email, host: 'admin-web' });

export const newPasswordUpdate = (token: string, new_password: string) =>
  API.post("/api/v1/login/change-password", { token, new_password });

export const searchEntitiesByTitleInDB = (userId: string, title: string, entityTypes: string[]) => {
  const searchEntities = entityTypes.map((type) => `&search_entities=${type}`).join('');
  return API.get(`/admin/search-record-in-db?user_id=${userId}${searchEntities}&search_query=${title}`);
}

export const searchIndexDocumentsAutocomoplete = (userId: string, title: string) => {
  return API.get(`/admin/search/documents/autocomplete?user_id=${userId}&query=${title}`);
}

export const searchIndexMaterials = (userId: string, title: string, limit: number) => {
  return API.get(`/admin/search/materials/?user_id=${userId}&query=${title}&length=${limit}`);
}

export const searchEntitiesByTitleInIndex = async (userId: string, title: string, entityTypes: string[], limit: number) => {
  const results: { [key: string]: any } = {
    autocomplete: [],
    materials: [],
  };
  if (entityTypes.includes('document autocomplete')){
    results.autocomplete = (await searchIndexDocumentsAutocomoplete(userId, title)).data.results;
  }
  if (entityTypes.includes('material')){
    results.materials = (await searchIndexMaterials(userId, title, limit)).data.results;
  }
  return results;
}